import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Common/Header';
import Footer from './components/Common/Footer';
import Homepage from './components/Homepage/Homepage';
import ProductList from './components/ProductList/Categorie';
import ProductDetails from './components/ProductDetails/ProductDetails';
import Cart from './components/Cart/Cart';
import Checkout from './components/Cart/Checkout';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import AdminDashboard from './components/Admin/AdminDashboard';
import ManageProducts from './components/Admin/ManageProducts';
import ManageOrders from './components/Admin/ManageOrders';
import BrandManager from './components/Admin/BrandManager';
import ModelManager from './components/Admin/ModelManager';
import BannerManager from './components/Admin/BannerManager';
import ProductEdit from './components/Admin/ProductEdit';
import Inventario from './components/Admin/Inventario';
import OrderDetails from './components/Admin/OrderDetails';

import Contattaci from './components/Common/Contattaci';
import OrderSuccess from './components/Cart/OrderSuccess';
import PaymentSuccess from './components/Cart/PaymentSuccess';
import Contacts from './components/Admin/Contacts';


import EmailTest from './components/Admin/EmailTest';

import SizeManager from './components/Admin/SizeManager';



import { auth } from './services/firebase';
import { v4 as uuidv4 } from 'uuid'; // Importa la funzione v4 di UUID



import ProductDetail from './components/ProductDetails/ProductdetailPage';
import ProductPage from './components/ProductDetails/ProductsPage';

const AuthContext = createContext();



const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Controlla se c'è uno stato di autenticazione salvato nel localStorage
    return JSON.parse(localStorage.getItem('isLoggedIn')) || false;
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
    });
  
    return unsubscribe;
  }, []);
  

  useEffect(() => {
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  // Componente di navigazione protetto
  const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = useContext(AuthContext);

    if (!isLoggedIn) {
      return <Navigate to="/login" />;
    }

    return <Component {...rest} />;
  };
// Verifica se l'identificatore dell'utente è già presente nella memoria locale
let userId = localStorage.getItem('userId');

// Se l'identificatore dell'utente non è presente, generane uno nuovo
if (!userId) {
  userId = uuidv4(); // Genera un nuovo identificatore univoco utilizzando UUID
  localStorage.setItem('userId', userId); // Salva l'identificatore dell'utente nella memoria locale
  console.log("Nuovo identificatore utente generato:", userId); // Aggiungi un console.log per mostrare l'identificatore dell'utente
} else {
  console.log("Identificatore utente già presente:", userId); // Aggiungi un console.log per mostrare l'identificatore dell'utente
}



  return (
    <Router>

        <Header />
        <AuthContext.Provider value={{ isLoggedIn }}>

        <Routes >
    
          <Route  path="/" element={<Homepage/>} />
          <Route path="/categorie" element={<ProductList/>} />
          <Route path="/product/:name" element={<ProductDetails/>} />
          <Route path="/product/:name" element={<ProductDetails/>} />

          <Route path="/cart" element={<Cart/>} />
          <Route path="/checkout" element={<Checkout/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/admin" element={<ProtectedRoute component={AdminDashboard} />} />
          <Route path="/admin/manage-products" element={<ProtectedRoute component={ManageProducts} />} />
          <Route path="/admin/manage-orders" element={<ProtectedRoute component={ManageOrders} />} />
          <Route path="/admin/brand-manager" element={<ProtectedRoute component={BrandManager} />} />
          <Route path="/admin/model-manager" element={<ProtectedRoute component={ModelManager} />} />
          <Route path="/admin/banner-manager" element={<ProtectedRoute component={BannerManager} />} />
          <Route path="/edit-product/products/:productId" element={<ProtectedRoute component={ProductEdit} />} />
          <Route path="/edit-product/:productId" element={<ProtectedRoute component={ProductEdit} />} />

          <Route path="/admin/inventario" element={<ProtectedRoute component={Inventario} />} />
          <Route path="/admin/contacts" element={<ProtectedRoute component={Contacts} />} />

          <Route path="/admin/sizemanager" element={<ProtectedRoute component={SizeManager} />} />



          
          <Route path="/categorie/uomo" element={<ProductList/>} />
          <Route path="/categorie/donna" element={<ProductList/>} />
          <Route path="/categorie/nuoviarrivi" element={<ProductList/>} />
          <Route path="/categorie/sneakers" element={<ProductList />} />
          <Route path="/categorie/modello/sneakers" element={<ProductList />} />

          <Route path="/orders/:orderId" element={<OrderDetails/>} />


          <Route path="/contattaci" element={<Contattaci/>} />

          <Route path="/email" element={<EmailTest/>} />








          <Route path="/products/:productId" element={<ProductDetail/>} />
          <Route path="/:productId" element={<ProductDetail/>} />

          <Route path="/detail" element={<ProductPage/>} />
          <Route path="/ordersuccess/:orderId" element={<OrderSuccess/>} />
          <Route path="/success/:orderId" element={<PaymentSuccess/>} />




      </Routes>
      </AuthContext.Provider>

        {/* <Footer /> */}

    </Router>
  );
};

export default App;