import React, { Fragment, useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useLocation } from 'react-router-dom';
import ProductCard from '../Common/ProductCard';
import BannerColor from './BannerColor';

// Importa i componenti di stile dal codice front-end fornito
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';

import { PlusIcon, MinusIcon } from '@heroicons/react/outline';
import { XIcon, FireIcon } from '@heroicons/react/solid';

// Componente di filtro per colori
const ColorFilter = ({ colors, activeColors, setActiveColors }) => {
  const handleColorChange = (color) => {
    setActiveColors((prevColors) =>
      prevColors.includes(color)
        ? prevColors.filter((c) => c !== color)
        : [...prevColors, color]
    );
  };

  return (
    <Disclosure as="div" className="border-b border-gray-200 py-6 px-4">
      {({ open }) => (
        <>
          <h3 className="-mx-2 -my-3 flow-root">
            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400 hover:text-gray-500">
              <span className="font-medium text-gray-900">Color</span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-4">
              {colors.map((color, index) => (
                <div key={color} className="flex items-center">
                  <input
                    id={`filter-color-${index}`}
                    name="color[]"
                    value={color}
                    type="checkbox"
                    checked={activeColors.includes(color)}
                    onChange={() => handleColorChange(color)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor={`filter-color-${index}`}
                    className="ml-3 text-sm text-gray-600"
                  >
                    {color}
                  </label>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

// Componente di filtro per categorie
const CategoryFilter = ({
  categories,
  activeCategories,
  setActiveCategories,
}) => {
  const handleCategoryChange = (category) => {
    setActiveCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
  };

  return (
    <Disclosure as="div" className="border-b border-gray-200 py-6 px-4">
      {({ open }) => (
        <>
          <h3 className="-mx-2 -my-3 flow-root">
            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400 hover:text-gray-500">
              <span className="font-medium text-gray-900">Category</span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-4">
              {categories.map((category, index) => (
                <div key={category} className="flex items-center">
                  <input
                    id={`filter-category-${index}`}
                    name="category[]"
                    value={category}
                    type="checkbox"
                    checked={activeCategories.includes(category)}
                    onChange={() => handleCategoryChange(category)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor={`filter-category-${index}`}
                    className="ml-3 text-sm text-gray-600"
                  >
                    {category}
                  </label>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

// Componente di filtro per taglie
const SizeFilter = ({ sizes, activeSizes, setActiveSizes }) => {
  const handleSizeChange = (size) => {
    setActiveSizes((prevSizes) =>
      prevSizes.includes(size)
        ? prevSizes.filter((s) => s !== size)
        : [...prevSizes, size]
    );
  };

  return (
    <Disclosure as="div" className="border-b border-gray-200 py-6 px-4">
      {({ open }) => (
        <>
          <h3 className="-mx-2 -my-3 flow-root">
            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400 hover:text-gray-500">
              <span className="font-medium text-gray-900">Size</span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-4">
              {sizes.map((size, index) => (
                <div key={size} className="flex items-center">
                  <input
                    id={`filter-size-${index}`}
                    name="size[]"
                    value={size}
                    type="checkbox"
                    checked={activeSizes.includes(size)}
                    onChange={() => handleSizeChange(size)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor={`filter-size-${index}`}
                    className="ml-3 text-sm text-gray-600"
                  >
                    {size}
                  </label>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

// Componente di filtro per brand
const BrandFilter = ({ brands, activeBrands, setActiveBrands }) => {
  const handleBrandChange = (brand) => {
    setActiveBrands((prevBrands) =>
      prevBrands.includes(brand)
        ? prevBrands.filter((b) => b !== brand)
        : [...prevBrands, brand]
    );
  };

  return (
    <Disclosure as="div" className="border-b border-gray-200 py-6 px-4">
      {({ open }) => (
        <>
          <h3 className="-mx-2 -my-3 flow-root">
            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400 hover:text-gray-500">
              <span className="font-medium text-gray-900">Brand</span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-4">
              {brands.map((brand, index) => (
                <div key={brand} className="flex items-center">
                  <input
                    id={`filter-brand-${index}`}
                    name="brand[]"
                    value={brand}
                    type="checkbox"
                    checked={activeBrands.includes(brand)}
                    onChange={() => handleBrandChange(brand)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor={`filter-brand-${index}`}
                    className="ml-3 text-sm text-gray-600"
                  >
                    {brand}
                  </label>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

// Componente di filtro per modelli
const ModelFilter = ({ models, activeModels, setActiveModels }) => {
  const handleModelChange = (model) => {
    setActiveModels((prevModels) =>
      prevModels.includes(model)
        ? prevModels.filter((m) => m !== model)
        : [...prevModels, model]
    );
  };

  return (
    <Disclosure as="div" className="border-b border-gray-200 py-6 px-4">
      {({ open }) => (
        <>
          <h3 className="-mx-2 -my-3 flow-root">
            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400 hover:text-gray-500">
              <span className="font-medium text-gray-900">Model</span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-4">
              {models.map((model, index) => (
                <div key={model} className="flex items-center">
                  <input
                    id={`filter-model-${index}`}
                    name="model[]"
                    value={model}
                    type="checkbox"
                    checked={activeModels.includes(model)}
                    onChange={() => handleModelChange(model)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor={`filter-model-${index}`}
                    className="ml-3 text-sm text-gray-600"
                  >
                    {model}
                  </label>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);
  const [activeSizes, setActiveSizes] = useState([]);
  const [activeColors, setActiveColors] = useState([]);
  const [activeBrands, setActiveBrands] = useState([]);
  const [activeModels, setActiveModels] = useState([]);
  const [activeIsNuovoArrivo, setActiveIsNuovoArrivo] = useState(null);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const db = getDatabase();
        const productsRef = ref(db, 'products');

        onValue(productsRef, (snapshot) => {
          const productsData = snapshot.val();

          if (productsData) {
            const productsArray = Object.values(productsData);
            setProducts(productsArray);
            setLoading(false);
          }
        });
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    let filtered = [...products];

    if (products.length > 0) {
      if (activeCategories.length > 0) {
        filtered = filtered.filter(
          (product) =>
            product.categoria &&
            product.categoria.some((category) =>
              activeCategories.includes(category)
            )
        );
      }

      if (activeSizes.length > 0) {
        filtered = filtered.filter(
          (product) =>
            product.sizes &&
            product.sizes.some((size) => activeSizes.includes(size))
        );
      }

      if (activeColors.length > 0) {
        const uniqueColors = [...new Set(activeColors)];
        filtered = filtered.filter(
          (product) =>
            product.colors &&
            product.colors.some((color) => uniqueColors.includes(color))
        );
      }

      if (activeBrands.length > 0) {
        const uniqueBrands = [...new Set(activeBrands)];
        filtered = filtered.filter(
          (product) =>
            product.brands &&
            product.brands.some((brand) => uniqueBrands.includes(brand))
        );
      }

      if (activeModels.length > 0) {
        const uniqueModels = [...new Set(activeModels)];
        filtered = filtered.filter(
          (product) =>
            product.models &&
            product.models.some((model) => uniqueModels.includes(model))
        );
      }

      if (activeIsNuovoArrivo !== null) {
        filtered = filtered.filter(
          (product) =>
            product.nuovoArrivo &&
            product.nuovoArrivo.includes(activeIsNuovoArrivo ? 'Sí' : 'No')
        );
      }
    }
    setFilteredProducts(filtered);
  }, [
    products,
    activeCategories,
    activeSizes,
    activeColors,
    activeBrands,
    activeModels,
    activeIsNuovoArrivo,
  ]);

  useEffect(() => {
    console.log('Current pathname:', location.pathname);
    if (location.pathname.startsWith('/categorie/')) {
      const category = location.pathname.split('/')[2];
      if (category === 'nuoviarrivi') {
        setActiveIsNuovoArrivo(true);
      } else if (category === 'sneakers') {
        setActiveModels(['Sneakers']);
      } else {
        setActiveIsNuovoArrivo(null);
        setActiveCategories([
          category.charAt(0).toUpperCase() + category.slice(1),
        ]);
      }
    } else {
      setActiveCategories([]);
      setActiveModels([]);
      setActiveIsNuovoArrivo(null);
    }
  }, [location.pathname]);

  return (
    <>
    <BannerColor/>
    <div className="bg-white mt-5">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filtri
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filtri */}
                  <form className="mt-4 border-t border-gray-200">
                    <ColorFilter
                      colors={[
                        ...new Set(
                          products.flatMap((product) => product.colors)
                        ),
                      ]}
                      activeColors={activeColors}
                      setActiveColors={setActiveColors}
                    />
                    <CategoryFilter
                      categories={['Uomo', 'Donna']}
                      activeCategories={activeCategories}
                      setActiveCategories={setActiveCategories}
                    />
                    <SizeFilter
                      sizes={[
                        ...new Set(
                          products.flatMap((product) => product.sizes)
                        ),
                      ]}
                      activeSizes={activeSizes}
                      setActiveSizes={setActiveSizes}
                    />
                    <BrandFilter
                      brands={[
                        ...new Set(
                          products.flatMap((product) => product.brands)
                        ),
                      ]}
                      activeBrands={activeBrands}
                      setActiveBrands={setActiveBrands}
                    />
                    <ModelFilter
                      models={[
                        ...new Set(
                          products.flatMap((product) => product.models)
                        ),
                      ]}
                      activeModels={activeModels}
                      setActiveModels={setActiveModels}
                    />
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-6">
            <div className="flex items-center">
              <button
                type="button"
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Filters</span>
                <span className="flex items-center">
                  <FireIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                  Filtri
                </span>
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filtri desktop */}
              <form className="hidden lg:block">
                <ColorFilter
                  colors={[
                    ...new Set(products.flatMap((product) => product.colors)),
                  ]}
                  activeColors={activeColors}
                  setActiveColors={setActiveColors}
                />
                <CategoryFilter
                  categories={['Uomo', 'Donna']}
                  activeCategories={activeCategories}
                  setActiveCategories={setActiveCategories}
                />
                <SizeFilter
                  sizes={[
                    ...new Set(products.flatMap((product) => product.sizes)),
                  ]}
                  activeSizes={activeSizes}
                  setActiveSizes={setActiveSizes}
                />
                <BrandFilter
                  brands={[
                    ...new Set(products.flatMap((product) => product.brands)),
                  ]}
                  activeBrands={activeBrands}
                  setActiveBrands={setActiveBrands}
                />
                <ModelFilter
                  models={[
                    ...new Set(products.flatMap((product) => product.models)),
                  ]}
                  activeModels={activeModels}
                  setActiveModels={setActiveModels}
                />
              </form>

              {/* Prodotti filtrati */}
              <div className="lg:col-span-3">
                <div className="flex flex-wrap">
                  {filteredProducts.map((product) => (
                    <ProductCard key={product.id} product={product} />
                  ))}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
    </>
  );
};

export default ProductList;
