import React from 'react';
import { Link } from 'react-router-dom';

function Grid() {
  return (
    <ul className="grid grid-cols-1  mt-3 lg:grid-cols-3">
      <li>
        <div className="relative block group">
          <img
            src="https://images.unsplash.com/photo-1618898909019-010e4e234c55?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
            className="object-cover w-full aspect-square"
          />
          <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
            <h3 className="text-xl font-medium text-white">SNEAKERS</h3>

            <Link
              to="/categorie/sneakers"
              className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
            >
              Vedi collezione
            </Link>
          </div>
        </div>
      </li>
      <li>
        <div className="relative block group">
          <img
            src="https://images.unsplash.com/photo-1624623278313-a930126a11c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
            className="object-cover w-full aspect-square"
          />
          <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
            <h3 className="text-xl font-medium text-white">DONNA</h3>
            <Link
              to="/categorie/donna"
              className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
            >
              Vedi collezione
            </Link>
          </div>
        </div>
      </li>
      <li className="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
        <div className="relative block group">
          <img
            src="https://images.unsplash.com/photo-1593795899768-947c4929449d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2672&q=80"
            className="object-cover w-full aspect-square"
          />
          <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
            <h3 className="text-xl font-medium text-white">UOMO</h3>
            <Link
              to="/categorie/uomo"
              className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
            >
              Vedi collezione
            </Link>
          </div>
        </div>
      </li>
    </ul>
  );
}

export default Grid;
