import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { auth, signInWithEmailAndPassword, signOut } from '../../services/firebase'; // Update the import statement


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Login successful!");
        navigate('/admin'); // Redirect to '/' path
      })
      .catch((error) => {
        console.error("Error during login:", error);
      });
  };


  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("Logout successful!");
        navigate('/'); // Redirect to the home page after logout
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  };

  return (
    <div className="mt-10 p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md space-y-5">
      <h2 className="text-2xl font-semibold text-center">Login</h2>
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Login
        </button>

        <button
        onClick={handleLogout}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        Logout
      </button>
      </form>
    </div>
  );
};

export default Login;
