import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';

const OrderDetail = () => {
  const {  orderId } = useParams();
  console.log("orderId:", orderId);  
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getDatabase();

  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId && orderId) {
      

      const orderRef = ref(db, `users/${userId}/orders/${orderId}`);
      const unsubscribe = onValue(orderRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setOrder(data);
          setLoading(false);
        } else {
          setLoading(false);
          setError('No order found.');
        }
      }, (error) => {
        setLoading(false);
        setError('Error loading order details.');
        console.error(error);
      });

      return () => {
        unsubscribe();
      };
    } else {
      setLoading(false);
      setError('Invalid parameters.');
    }
  }, [db, userId, orderId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!order) {
    return <div>No order found.</div>;
  }

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md">
    <h2 className="text-2xl font-semibold mb-4">Dettagli dell'ordine</h2>
    <div className="mb-4">
      <p className="font-semibold">Nome:</p>
      <p>{order.name}</p>
    </div>
    <div className="mb-4">
      <p className="font-semibold">Email:</p>
      <p>{order.email}</p>
    </div>
    <div className="mb-4">
      <p className="font-semibold">Indirizzo:</p>
      <p>{order.address}</p>
    </div>
    <div className="mb-4">
      <p className="font-semibold">Città:</p>
      <p>{order.city}</p>
    </div>
    <div className="mb-4">
      <p className="font-semibold">Codice postale:</p>
      <p>{order.postalCode}</p>
    </div>
    <div className="mb-4">
      <p className="font-semibold">Status:</p>
      <p>{order.status}</p>
    </div>
    <div className="mb-4">
      <p className="font-semibold">Totale:</p>
      <p>€ {order.total}</p>
    </div>
    <h3 className="text-lg font-semibold mb-2">Prodotti:</h3>
    <ul>
      {order.products.map((product, index) => (
        <li key={index} className="mb-4">
          <p className="font-semibold">Prodotto ID:</p>
          <p>{product.productId}</p>
          <p className="font-semibold">Quantità:</p>
          <p>{product.quantity}</p>
        </li>
      ))}
    </ul>
  </div>
  );
};

export default OrderDetail;
