import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, push, set } from 'firebase/database';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'; // Importa la funzione v4 di UUID


// Componente per singolo prodotto nella lista
const ProductItem = ({ product, handleAddToCart }) => {
  const { id, name, imageUrl, price } = product;

  return (
    <li key={id}>
      <Link to={`/${id}`}>
        <img src={imageUrl} alt={name} />
        <h3>{name}</h3>
        <p>${price}</p>
      </Link>
      <button onClick={() => handleAddToCart(product)}>Aggiungi al carrello</button>
    </li>
  );
};

const ProductList = () => {
  const [products, setProducts] = useState([]);

  // Caricamento dei prodotti dall'API al caricamento del componente
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const db = getDatabase();
        const productsRef = ref(db, 'products');

        onValue(productsRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const productList = Object.keys(data).map((productId) => ({
              id: productId,
              ...data[productId],
            }));
            setProducts(productList);
          }
        });
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Funzione per aggiungere un prodotto al carrello
  const handleAddToCart = (product) => {
    const userId = localStorage.getItem('userId'); // Ottieni l'identificatore dell'utente dalla memoria locale
    const db = getDatabase();
    const cartItemsRef = ref(db, `users/${userId}/cartItems`); // Utilizza l'identificatore dell'utente per ottenere il riferimento al suo carrello
    const newCartItemRef = push(cartItemsRef); // Ottieni il riferimento a un nuovo elemento
    const newCartItemKey = newCartItemRef.key; // Ottieni la chiave del nuovo elemento

    // Genera un UUID per l'ID del nuovo elemento
    const newUUID = uuidv4();

    // Utilizza l'UUID come ID per il nuovo elemento
    set(ref(db, `users/${userId}/cartItems/${newUUID}`), {
      id: newUUID,
      name: product.name,
      price: product.price,
      img: product.imageUrl,
      quantity: 1
    }).then(() => {
      console.log("Product added to cart:", product.name);
    }).catch((error) => {
      console.error("Error adding product to cart:", error);
    });
  };
  return (
    <div>
      <h2>Lista Prodotti</h2>
      <ul>
        {products.map((product) => (
          <ProductItem key={product.id} product={product} handleAddToCart={handleAddToCart} />
        ))}
      </ul>
    </div>
  );
};

export default ProductList;
