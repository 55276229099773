import React from 'react';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-gray-300 py-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
        <p className="text-sm text-center md:text-left mb-4 md:mb-0">
          <FontAwesomeIcon icon={faLeaf} /> Sustainably made by Denis Cartin
        </p>
        <p className="text-sm text-center md:text-left mb-4 md:mb-0">
          © 2024 Plastic Store
        </p>
        <div className="flex justify-center md:justify-end space-x-4">
        <a href="/contattaci" className="text-gray-300 hover:text-white transition-colors duration-300">
  Chi siamo
</a>

          <a
            href="#"
            className="text-gray-300 hover:text-white transition-colors duration-300"
          >
            Cookie e Privacy
          </a>
          <a
            href="#"
            className="text-gray-300 hover:text-white transition-colors duration-300"
          >
            Termini e condizioni
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
