import React, { useState, useEffect } from 'react';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';

const BannerList = () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const storage = getStorage();
        const bannersRef = ref(storage, 'banners/home');
        const bannersFolder = await listAll(bannersRef);

        const bannerPromises = bannersFolder.items.map(async (bannerRef) => {
          const bannerName = bannerRef.name;
          const bannerUrl = await getDownloadURL(bannerRef);

          return { name: bannerName, imageUrl: bannerUrl };
        });

        const resolvedBanners = await Promise.all(bannerPromises);
        setBanners(resolvedBanners);
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 ">
    {banners.map((banner) => (
     <div
  key={banner.name}
  className="relative w-screen block h-64 overflow-hidden"
>
  <img
    src={banner.imageUrl}
    alt={banner.name}
    className="w-full h-full object-cover"
  />
  <div className="absolute inset-0 flex items-center justify-center">
  </div>
</div>
        
    ))}
  </div>
  );
};

export default BannerList;