import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth"; // Importa signInWithEmailAndPassword, signOut
import { getDatabase } from 'firebase/database';
import { getStorage} from 'firebase/storage';
import { getFunctions } from "firebase/functions";





const firebaseConfig = {
    apiKey: "AIzaSyA28_5cUiTttItqEzBJF25aPXxfMphjA7w",
    authDomain: "plasticstorevelletri-6d5ca.firebaseapp.com",
    projectId: "plasticstorevelletri-6d5ca",
    storageBucket: "plasticstorevelletri-6d5ca.appspot.com",
    messagingSenderId: "1034480042516",
    appId: "1:1034480042516:web:6f8309f70e2830c2f2dd01",
    measurementId: "G-2GMV44TBFH"
  };




const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app);
const functions = getFunctions(app); 

export { auth, database, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, storage, getStorage, functions, app};