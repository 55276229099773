import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, get, remove } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddSizeForm = () => {
  const db = getDatabase();
  const [sizes, setSizes] = useState([]);
    const [sizeData, setSizeData] = useState({
    name: '',
  });
  const [loading, setLoading] = useState(false); // Variabile di stato per il caricamento
  const [message, setMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSizeData({ ...sizeData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!sizeData || !sizeData.name) {
      return;
    }
  
    setLoading(true);
  
    const sizeId = uuidv4();
    const sizesRef = ref(db, `sizes/${sizeId}`);
    await set(sizesRef, {
      id: sizeId,
      name: sizeData.name,
    })
      .then(() => {
        console.log('Size added successfully!');
        toast.success('Size added successfully!');
        setSizeData({
          name: '',
        });
        setSizes((prevSizes) => [...prevSizes, { id: sizeId, name: sizeData.name }]);
      })
      .catch((error) => {
        console.error('Error adding size:', error);
        toast.error('Error adding size');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  useEffect(() => {
    const fetchSizes = async () => {
      try {
        const sizesRef = ref(db, 'sizes');
        const snapshot = await get(sizesRef);
        if (snapshot.exists()) {
          const sizesData = snapshot.val();
          const sizesList = Object.values(sizesData);
          setSizes(sizesList);
        }
      } catch (error) {
        console.error('Error fetching sizes:', error);
      }
    };
    fetchSizes();
  }, [db]);
 
  
  const handleDelete = async (sizeId) => {
    try {
      const sizeRef = ref(db, `sizes/${sizeId}`);
      await remove(sizeRef);
      setSizes(sizes.filter((size) => size.id !== sizeId));
      toast.success('Size deleted successfully!');
    } catch (error) {
      console.error('Error deleting size:', error);
      toast.error('Error deleting size');
    }
  };
  return (
    <div>
      <ToastContainer />
      {message && <p className="text-green-500">{message}</p>}
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-lg mx-auto mt-10 p-8 bg-white shadow-lg rounded-lg"
      >
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
            Taglia:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Inserisci la taglia"
            value={sizeData.name}
            onChange={handleInputChange}
            className="input-field"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={loading}
        >
          {loading ? 'Sto aggiungendo la taglie...' : 'Aggiungi Taglia'}
        </button>
      </form>
      <div className="mt-10">
      <h2 className="text-2xl font-bold mb-4">Taglie Aggiunte</h2>
      {sizes.length > 0 ? (
        <ul className="space-y-2">
          {sizes.map((size) => (
            <li key={size.id} className="bg-gray-100 p-4 rounded-lg flex justify-between items-center">
              {size.name}
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => handleDelete(size.id)}
              >
                Elimina
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>Nessuna taglia aggiunta.</p>
      )}
    </div>
     
    </div>
  );
};

export default AddSizeForm;
