import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Importa useParams da react-router-dom
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, set, get } from 'firebase/database';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  'pk_test_51OotTMESSQs3JMPokQklRTs9NB6TQhScmjaGQgB5pWlnWCFaOR70NZxMNAxBTvJRgWsyiWyzEiQ2PaKu5pomkHra00btUC87uj'
);

const OrderSuccess = () => {
  const [orderDetails, setOrderDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { orderId } = useParams(); // Ottieni l'orderId dalla barra degli indirizzi
  const [total, setTotal] = useState(0);
  const [paymentSuccess, setPaymentSuccess] = useState(false); // State per gestire il successo del pagamento
  const [cartItems, setCartItems] = useState([]);
  const [buttonText, setButtonText] = useState('Procedi al pagamento');


  const db = getDatabase();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const orderSnapshot = await get(
          ref(db, `users/${userId}/orders/${orderId}`)
        );
        if (orderSnapshot.exists()) {
          setOrderDetails(orderSnapshot.val());
        } else {
          setError('Order not found');
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
        setError('Failed to fetch order details');
      }
      setIsLoading(false);
    };

    fetchOrder();
  }, [db, orderId, orderDetails, isLoading, error]);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const cartItemsRef = ref(db, `users/${userId}/cartItems`);
        const snapshot = await get(cartItemsRef);
        if (snapshot.exists()) {
          const cartItemsData = snapshot.val();
          const cartItemsArray = Object.values(cartItemsData);
          setCartItems(cartItemsArray);
          const totalPrice = cartItemsArray.reduce(
            (acc, item) => acc + item.price * item.quantity,
            0
          );
          setTotal(totalPrice);
        }
      } catch (error) {
        console.error('Error fetching cart items:', error);
        setError('Failed to fetch cart items');
      }
    };

    fetchCartItems();
  }, [db]);

  const handleCheckout = async () => {
    setIsLoading(true);
    setError(null);
    setButtonText('Stai per essere reindirizzato alla pagina del pagamento');


    try {
      const stripe = await stripePromise;
      const userId = localStorage.getItem('userId'); // Assumendo che tu li abbia salvati nel localStorage

      const response = await fetch(
        'https://us-central1-plasticstorevelletri-6d5ca.cloudfunctions.net/createCheckoutSession',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ items: cartItems, total, userId, orderId }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        setError(`Error during checkout: ${result.error.message}`);
      } else {
        setPaymentSuccess(true); // Imposta il successo del pagamento su true se il pagamento va a buon fine
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      setError('An error occurred during checkout. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
      <div className="flex justify-start item-start space-y-2 flex-col ">
        <h1 className="text-2xl lg:text-4xl font-semibold leading-7 lg:leading-9  text-gray-800">
          Ci siamo quasi! Controlla le tue informazioni e procedi al pagamento
        </h1>
        {/* <p className="text-base font-medium leading-6 text-gray-600">21st Mart 2021 at 10:34 PM</p> */}
      </div>

      <button
  onClick={handleCheckout}
  className="bg-indigo-500 text-white px-4 py-2 rounded-lg mr-2 focus:outline-none hover:bg-indigo-600"
>
  {buttonText}
</button>

      <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch  w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
        <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
          <div className="flex flex-col justify-start items-start bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
            {orderDetails.products && orderDetails.products.length > 0 ? (
              orderDetails.products.map((product, index) => (
                <div
                  key={index}
                  className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full pb-8 space-y-4 md:space-y-0"
                >
                  <div className="w-full flex flex-col justify-start items-start space-y-8">
                    <h3 className="text-xl xl:text-2xl font-semibold leading-6 text-gray-800">
                      {product.name}
                    </h3>
                    <div className="flex justify-start items-start flex-col space-y-2">
                      <p className="text-sm leading-none text-gray-800">
                        <span className="text-gray-300">Taglia: </span>
                        {product.size}
                      </p>
                      <p className="text-sm leading-none text-gray-800">
                        <span className="text-gray-300">Colore: </span>
                        {product.color}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between space-x-8 items-start w-full">
                    <p className="text-base xl:text-lg font-semibold leading-6 text-gray-800">
                      €{product.price}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p>No products found</p>
            )}
          </div>
          <div className="flex justify-center md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
            <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6   ">
              <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6">
                <h3 className="text-xl font-semibold leading-5 text-gray-800">
                  Dettagli ordine
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                  <div className="flex justify-between w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Subtotale
                    </p>
                    <p className="text-base leading-4 text-gray-600">
                      €{orderDetails.total}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Spedizione
                    </p>
                    <p className="text-base leading-4 text-gray-600">€8.00</p>{' '}
                    {/* Assuming fixed shipping cost */}
                  </div>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-base font-semibold leading-4 text-gray-800">
                    Totale
                  </p>
                  <p className="text-base font-semibold leading-4 text-gray-600">
                    €{orderDetails.total + 8}
                  </p>{' '}
                  {/* Total including shipping */}
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div className="bg-gray-50 w-full xl:w-96 flex justify-between items-center md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col ">
          <h3 className="text-xl font-semibold leading-5 text-gray-800">
            Dettagli acquirente
          </h3>
          <div className="flex  flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0 ">
            <div className="flex flex-col justify-start items-start flex-shrink-0">
              <div className="flex justify-center  w-full  md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                <div className=" flex justify-start items-start flex-col space-y-2">
                  <p className="text-base font-semibold leading-4 text-left text-gray-800">
                    {orderDetails.name}
                  </p>
                </div>
              </div>

              <div className="flex justify-center  md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
                    stroke="#1F2937"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 7L12 13L21 7"
                    stroke="#1F2937"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="cursor-pointer text-sm leading-5 text-gray-800">
                  {orderDetails.email}
                </p>
              </div>
            </div>
            <div className="flex justify-between xl:h-full  items-stretch w-full flex-col mt-6 md:mt-0">
              <div className="flex justify-center md:justify-start xl:flex-col flex-col md:space-x-6 lg:space-x-8 xl:space-x-0 space-y-4 xl:space-y-12 md:space-y-0 md:flex-row  items-center md:items-start ">
                <div className="flex justify-center md:justify-start  items-center md:items-start flex-col space-y-4 xl:mt-8">
                  <p className="text-base font-semibold leading-4 text-center md:text-left text-gray-800">
                    Indirizzo di spedizione
                  </p>
                  <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                    Indririzzo: {orderDetails.address}
                  </p>
                  <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                    Città: {orderDetails.city}
                  </p>
                  <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                    CAP: {orderDetails.postalCode}
                  </p>
                </div>
                {/* <div className="flex justify-center md:justify-start  items-center md:items-start flex-col space-y-4 ">
                                    <p className="text-base font-semibold leading-4 text-center md:text-left text-gray-800">Billing Address</p>
                                    <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">180 North King Street, Northhampton MA 1060</p>
                                </div> */}
              </div>
              {/* <div className="flex w-full justify-center items-center md:justify-start md:items-start">
                                <button className="mt-6 md:mt-0 py-5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 border border-gray-800 font-medium w-96 2xl:w-full text-base leading-4 text-gray-800">Edit Details</button>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
