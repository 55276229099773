import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, get } from 'firebase/database';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const stripePromise = loadStripe(
  'pk_test_51OotTMESSQs3JMPokQklRTs9NB6TQhScmjaGQgB5pWlnWCFaOR70NZxMNAxBTvJRgWsyiWyzEiQ2PaKu5pomkHra00btUC87uj'
);

const Checkout = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [paymentSuccess, setPaymentSuccess] = useState(false); // State per gestire il successo del pagamento

  const db = getDatabase();

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const cartItemsRef = ref(db, `users/${userId}/cartItems`);
        const snapshot = await get(cartItemsRef);
        if (snapshot.exists()) {
          const cartItemsData = snapshot.val();
          const cartItemsArray = Object.values(cartItemsData);
          setCartItems(cartItemsArray);
          const totalPrice = cartItemsArray.reduce(
            (acc, item) => acc + item.price * item.quantity,
            0
          );
          setTotal(totalPrice);
        }
      } catch (error) {
        console.error('Error fetching cart items:', error);
        setError('Failed to fetch cart items');
      }
    };

    fetchCartItems();
  }, [db]);

 const handleCheckout = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const stripe = await stripePromise;
      const userId = localStorage.getItem('userId'); // Assumendo che tu li abbia salvati nel localStorage

      const response = await fetch(
        'https://us-central1-plasticstorevelletri-6d5ca.cloudfunctions.net/createCheckoutSession',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ items: cartItems, total }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        setError(`Error during checkout: ${result.error.message}`);
      } else {
        setPaymentSuccess(true); // Imposta il successo del pagamento su true se il pagamento va a buon fine
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      setError('An error occurred during checkout. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlaceOrder = async () => {
    if (!name || !email || !address || !city || !postalCode) {
      setError('Please fill in all the required fields.');
      toast.error('Completare correttamente i campi richiesti'); // Errore: mostra un toast di errore
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const userId = localStorage.getItem('userId');
      const orderId = uuidv4();
      const orderRef = ref(db, `users/${userId}/orders/${orderId}`);
      const productRefs = cartItems.map((item) => ({
        productId: item.id,
        name: item.name,
        quantity: item.quantity,
        price: item.price,
        color: item.color,
        size: item.size,
      }));
      const order = {
        products: productRefs,
        total,
        status: 'Da spedire',
        pagato: 'NO',
        name,
        email,
        address,
        city,
        postalCode,
      };
      await set(orderRef, order);
      setCartItems([]);
      console.log('Order placed successfully!');
      navigate(`/ordersuccess/${orderId}`);
    } catch (error) {
      console.error('Error placing order:', error);
      setError('Failed to place order. Please try again later.');
    }
  };

  const handlePayNow = async () => {
    // Resetta gli errori e mostra il caricamento
    setIsLoading(true);
    setError(null);

    try {
      // Esegue la funzione di checkout
      await handlePlaceOrder ();

      // Esegue la funzione di place order solo se il checkout ha avuto successo
      if (!error) {
        await handleCheckout();
      }
    } catch (error) {
      console.error('Error during payment:', error);
      setError('An error occurred during payment. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <ToastContainer />
      <h2 className="text-2xl font-semibold mb-4">Checkout</h2>
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700">
          Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="block w-full px-4 py-2 mt-1 rounded-lg border focus:outline-none focus:border-indigo-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-gray-700">
          Email
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="block w-full px-4 py-2 mt-1 rounded-lg border focus:outline-none focus:border-indigo-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="address" className="block text-gray-700">
          Address
        </label>
        <input
          type="text"
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="block w-full px-4 py-2 mt-1 rounded-lg border focus:outline-none focus:border-indigo-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="city" className="block text-gray-700">
          City
        </label>
        <input
          type="text"
          id="city"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          className="block w-full px-4 py-2 mt-1 rounded-lg border focus:outline-none focus:border-indigo-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="postalCode" className="block text-gray-700">
          Postal Code
        </label>
        <input
          type="text"
          id="postalCode"
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
          className="block w-full px-4 py-2 mt-1 rounded-lg border focus:outline-none focus:border-indigo-500"
        />
      </div>
      <ul className="mb-4">
      {cartItems && cartItems.length > 0 ? (
        cartItems.map((item) => (
          <li key={item.id} className="text-gray-700">
            {item.name} - €{item.price} x {item.quantity}
          </li>
        ))
      ) : (
        <li className="text-gray-700">No items in cart</li>
      )}
    </ul>
      <div className="mb-4">
        <strong>Total:</strong> €{total.toFixed(2)}
      </div>
      {/* <button
        onClick={handleCheckout}
        className="bg-indigo-500 text-white px-4 py-2 rounded-lg mr-2 focus:outline-none hover:bg-indigo-600"
      >
        Pay with Stripe
      </button> */}
      <button
        onClick={handlePlaceOrder}
        className="bg-gray-500 text-white px-4 py-2 rounded-lg focus:outline-none hover:bg-gray-600"
      >
        Ordina ora!
      </button>
      {/* <button
        onClick={handlePayNow}
        className="bg-indigo-500 text-white px-4 py-2 rounded-lg mr-2 focus:outline-none hover:bg-indigo-600"
      >
        Pay Now!
      </button> */}
    </div>
  );
};

export default Checkout;
