import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set } from 'firebase/database';

const CookieBanner = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  useEffect(() => {
    // Controlla se l'utente ha già accettato i cookie
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted === 'true') {
      setAcceptedCookies(true);
    }
  }, []);

  const acceptCookies = () => {
    setAcceptedCookies(true);

    // Salva nello stato del localStorage che i cookie sono stati accettati
    localStorage.setItem('cookiesAccepted', 'true');

    // Ottieni l'ID dell'utente dal localStorage
    const userId = localStorage.getItem('userId');

    // Controlla se l'ID dell'utente è presente nel localStorage
    if (!userId) {
      console.error('User ID not found in localStorage');
      return;
    }

    // Ottieni un riferimento al database
    const db = getDatabase();

    // Imposta il valore "cookie:accepted" nel database per l'utente corrente
    set(ref(db, `users/${userId}/cookie`), 'accepted')
      .then(() => {
        console.log('Accettazione dei cookie registrata nel database per l\'utente', userId);
      })
      .catch((error) => {
        console.error('Errore durante il salvataggio dell\'accettazione dei cookie nel database:', error);
      });
  };

  if (acceptedCookies) {
    return null; // Nascondi il banner se i cookie sono stati accettati
  }

  return (
    <div className="bg-gray-800 text-white fixed bottom-0 left-0 w-full py-4 px-6 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <p className="text-sm">Questo sito utilizza i cookie per garantire una migliore esperienza utente. Continuando la navigazione, acconsenti al loro utilizzo.</p>
        <button
          onClick={acceptCookies}
          className="text-gray-300 hover:text-white transition-colors duration-300"
        >
          Accetta
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
