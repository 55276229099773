import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, update, remove } from 'firebase/database';
import { Link } from 'react-router-dom';
import {ToastContainer, toast } from 'react-toastify';

const OrderInventory = () => {
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const db = getDatabase();

  useEffect(() => {
    const ordersRef = ref(db, 'users');
    const unsubscribe = onValue(ordersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const orderList = Object.keys(data).flatMap((userId) => {
          if (data[userId].orders) {
            return Object.keys(data[userId].orders).map((orderId) => ({
              userId,
              orderId,
              ...data[userId].orders[orderId],
            }));
          }
          return [];
        });
        setOrders(orderList);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, [db]);

  const handleStatusChange = async (userId, orderId) => {
    try {
      const orderRef = ref(db, `users/${userId}/orders/${orderId}`);
      await update(orderRef, {
        status: 'spedito', // Sostituisci 'nuovoStato' con lo stato desiderato
      });
      toast.success('Stato dell\'ordine aggiornato con successo!');
    } catch (error) {
      console.error('Errore durante l\'aggiornamento dello stato dell\'ordine:', error);
      toast.error('Si è verificato un errore durante l\'aggiornamento dello stato dell\'ordine.');
    }
  };
  


  const handleDeleteOrder = async (userId, orderId) => {
    try {
      const orderRef = ref(db, `users/${userId}/orders/${orderId}`);
      await remove(orderRef);
      toast.success('Ordine eliminato con successo!');
    } catch (error) {
      console.error('Errore durante l\'eliminazione dell\'ordine:', error);
      toast.error('Si è verificato un errore durante l\'eliminazione dell\'ordine.');
    }
  };
  

  // Filter orders based on search term
  const filteredOrders = orders.filter((order) =>
    order.orderId.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto p-4">
    <ToastContainer />
      <h1 className="text-2xl font-bold mb-4">Inventario Ordini</h1>
      <input
        type="text"
        placeholder="Cerca per ID Ordine"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="border border-gray-300 rounded px-3 py-1 mb-4"
      />
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th className="px-4 py-2">ID Ordine</th>
            <th className="px-4 py-2">Totale</th>
            <th className="px-4 py-2">Stato</th>
            <th className="px-4 py-2">Pagato</th>
            <th className="px-4 py-2">Azioni</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => (
            <tr key={order.userId}>
              <td className="border px-4 py-2">{order.orderId}</td>{' '}
              {/* Aggiungi l'ID dell'ordine */}
              <td className="border px-4 py-2">€ {order.total}</td>
              <td
                className={`border px-4 py-2 ${
                  order.status === 'spedito' ? 'text-green-500' : 'text-red-500'
                }`}
              >
                {order.status}
              </td>
              <td
                className={`border px-4 py-2 ${
                  order.pagato === 'SI' ? 'text-green-500' : 'text-red-500'
                }`}
              >
                {order.pagato}
              </td>{' '}
              <td className="border px-4 py-2">
                <Link
                  to={`/orders/${order.orderId}`}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Dettagli
                </Link>
                {order.status !== 'spedito' && (
    <button
      onClick={() => handleStatusChange(order.userId, order.orderId)}
      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
    >
      Spedito
    </button>
  )}
                <button
                   onClick={() => handleDeleteOrder(order.userId, order.orderId)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Elimina
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderInventory;
