import React, { useState, useEffect } from 'react';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';

const BrandList = () => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const storage = getStorage();
        const brandsRef = ref(storage, 'brands');
        const brandFolders = await listAll(brandsRef);
  
        const brandPromises = brandFolders.prefixes.map(async (brandFolderRef) => {
          const brandName = brandFolderRef.name;
          const imagesFolderRef = ref(storage, `brands/${brandName}`);
          const imagesFolder = await listAll(imagesFolderRef);
  
          // Assumiamo che ci sia solo un'immagine per ogni brand
          const imageRef = imagesFolder.items[0];
          const imageName = imageRef.name;
          const imageUrl = await getDownloadURL(imageRef);
  
          return { name: brandName, imageUrl, imageName };
        });
  
        const resolvedBrands = await Promise.all(brandPromises);
        setBrands(resolvedBrands);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };
  
    fetchBrands();
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 mt-10">
      {brands.map((brand) => (
        <div
          key={brand.name}
          className="relative w-full h-64 overflow-hidden  shadow-lg"
        >
          <img
            src={brand.imageUrl}
            alt={brand.name}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center">
            <span className="text-white text-shadow-lg font-bold text-2xl">{brand.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BrandList;