import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue, push, set } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const ProductDetails = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [addedToCart, setAddedToCart] = useState(false);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const containerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  useEffect(() => {
    const db = getDatabase();
    const productRef = ref(db, `products/${productId}`);

    onValue(productRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setProduct(data);
        fetchRecommendedProducts(data.category, 1);
      }
    });
  }, [productId]);

  const fetchRecommendedProducts = (category, page) => {
    const db = getDatabase();
    const productsRef = ref(db, 'products');

    setIsLoading(true);

    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      const filteredProducts = Object.values(data).filter(
        (p) => p.category === category && p.id !== productId
      );
      const randomizedProducts = filteredProducts.sort(
        () => Math.random() - 0.5
      );
      const slicedProducts = randomizedProducts.slice((page - 1) * 3, page * 3);
      setRecommendedProducts((prevProducts) => [
        ...prevProducts,
        ...slicedProducts,
      ]);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        product && // Verifica se product è definito
        containerRef.current &&
        window.scrollY + window.innerHeight >=
          containerRef.current.offsetTop + containerRef.current.offsetHeight &&
        !isLoading
      ) {
        setPage((prevPage) => prevPage + 1);
        fetchRecommendedProducts(product.category, page + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [product, isLoading, page]);

  if (!product) {
    return (
      <div className="flex justify-center items-center h-screen">
        Caricamento in corso...
      </div>
    );
  }

  const handleAddToCart = () => {
    if (!product.id) {
      console.error('Product ID is not defined.');
      return;
    }

    // Controllo se sia il colore che la taglia sono selezionati
    if (!selectedColor || !selectedSize) {
      console.error('Please select color and size before adding to cart.');
      toast.error('Selezionare i filtri colore e taglia'); // Errore: mostra un toast di errore
      return;
    }

    const userId = localStorage.getItem('userId');
    const db = getDatabase();
    const cartItemsRef = ref(db, `users/${userId}/cartItems`);
    const newCartItemRef = push(cartItemsRef);
    const newUUID = uuidv4();

    set(ref(db, `users/${userId}/cartItems/${newUUID}`), {
      id: newUUID,
      name: product.name,
      price: product.price,
      sku: product.SKU,
      img: product.imageUrls,
      quantity: 1,
      size: selectedSize,
      color: selectedColor,
    })
      .then(() => {
        console.log('Product added to cart:', product.name);
        toast.success('Prodotto aggiunto al carrello');
        setAddedToCart(true);
      })
      .catch((error) => {
        console.error('Error adding product to cart:', error);
      });
  };

  return (
    <>
      <div className="md:flex items-start justify-center py-12 2xl:px-20 md:px-6 px-4">
        <ToastContainer />
        <div className="xl:w-2/6 lg:w-2/5 w-80 block">
  {product.imageUrls.map((imageUrl, index) => (
    <img
      key={index}
      src={imageUrl}
      alt={`Image ${index}`}
      className="w-full mb-2"
    />
  ))}
</div>

        <div className="xl:w-2/5 md:w-1/2 lg:ml-8 md:ml-6 md:mt-0 mt-6">
          <div className="border-b border-gray-200 pb-6">
            <p className="text-sm leading-none text-gray-600">
              {product.brand}
            </p>
            <h1
              className="
							lg:text-2xl
							text-xl
							font-semibold
							lg:leading-6
							leading-7
							text-gray-800
							mt-2
						"
            >
              {product.name}
            </h1>
          </div>
          <div className="py-4 border-b border-gray-200 flex items-center justify-between">
            <p className="text-base leading-4 text-gray-800">Colore</p>
            <div className="flex items-center justify-center">
              <div className="flex flex-wrap gap-2">
                {product.colors.map((color, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedColor(color)}
                    className={`px-4 py-2 rounded-lg transition-colors duration-300 ${
                      selectedColor === color
                        ? 'bg-gray-900 text-white hover:bg-gray-700'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    {color}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="py-4 border-b border-gray-200 flex items-center justify-between">
            <p className="text-base leading-4 text-gray-800">Taglia</p>
            <div className="flex items-center justify-center">
              <div className="flex flex-wrap gap-2">
                {product.sizes.map((size, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedSize(size)}
                    className={`px-4 py-2 rounded-lg transition-colors duration-300 ${
                      selectedSize === size
                        ? 'bg-gray-900 text-white hover:bg-gray-700'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <button
            className="
						focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800
						text-base
						flex
						items-center
						justify-center
						leading-none
						text-white
						bg-gray-800
						w-full
						py-4
						hover:bg-gray-700
					"
            onClick={handleAddToCart}
          >
            Aggiungi al carrello
          </button>
          <div>
            <p className="xl:pr-48 text-base lg:leading-tight leading-normal text-gray-600 mt-7">
              Descrizione: {product.description}
            </p>
            <p className="text-base leading-4 mt-7 text-gray-600">
              SKU: {product.SKU}
            </p>
            <p className="text-base leading-4 mt-4 text-gray-600">
              Vestibilità: valore
            </p>
            {/* <p className="text-base leading-4 mt-4 text-gray-600">
              Height: 10 inches
            </p>
            <p className="text-base leading-4 mt-4 text-gray-600">
              Depth: 5.1 inches
            </p> */}
            <p className="md:w-96 text-base leading-normal text-gray-600 mt-4">
              Materiale: 100% calf leather, inside: 100% lamb leather
            </p>
          </div>
          <div>
            <div className="border-t border-b py-4 mt-7 border-gray-200">
              <div
                onClick={() => setShow(!show)}
                className="flex justify-between items-center cursor-pointer"
              >
                <p className="text-base leading-4 text-gray-800">
                  Spedizione e reso
                </p>
                <button
                  className="
									cursor-pointer
									focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
									rounded
								"
                  aria-label="show or hide"
                >
                  <svg
                    className={
                      'transform ' + (show ? 'rotate-180' : 'rotate-0')
                    }
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 1L5 5L1 1"
                      stroke="#4B5563"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div
                className={
                  'pt-4 text-base leading-normal pr-12 mt-4 text-gray-600 ' +
                  (show ? 'block' : 'hidden')
                }
                id="sect"
              >
                Spediamo entro 2 giorni ed il prodotto arriva entro 4/5 giorni.
                Il costo del reso è a carico del cliente come definito nei
                termini e condizioni.
              </div>
            </div>
          </div>
          <div>
            <div className="border-b py-4 border-gray-200">
              <div
                onClick={() => setShow2(!show2)}
                className="flex justify-between items-center cursor-pointer"
              >
                <p className="text-base leading-4 text-gray-800">Contattaci</p>
                <button
                  className="
									cursor-pointer
									focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
									rounded
								"
                  aria-label="show or hide"
                >
                  <svg
                    className={
                      'transform ' + (show2 ? 'rotate-180' : 'rotate-0')
                    }
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 1L5 5L1 1"
                      stroke="#4B5563"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div
                className={
                  'pt-4 text-base leading-normal pr-12 mt-4 text-gray-600 ' +
                  (show2 ? 'block' : 'hidden')
                }
                id="sect"
              >
                Per ogni domanda relativa agli articoli o agli ordini contattare
                il nostro supporto clienti.
              </div>
            </div>
            <div>
              <h1
                className="font-semibold mt-10 mb-5"
                style={{ fontSize: '3vh' }}
              >
                Ti potrebbe piacere anche
              </h1>
              <div
                className="flex gap-8 overflow-x-auto pb-8 w-screen"
                ref={containerRef}
              >
                {recommendedProducts.map((p) => (
                  <div
                    key={p.id}
                    className="bg-white shadow-lg overflow-hidden flex-shrink-0 w-64"
                  >
                    <Link to={`/${product.id}`} className="block">
                      <img
                        src={p.imageUrl}
                        alt={p.name}
                        className="w-full h-40 object-cover"
                      />
                      <div className="p-4">
                        <h3
                          className="lg:text-2xl
                                    text-xl
                                    font-semibold
                                    lg:leading-6
                                    leading-7
                                    text-gray-800
                                    mt-2
                                    mb-2"
                        >
                          {p.name}
                        </h3>
                        <p className="text-lg font-medium text-gray-700">
                          Prezzo: {p.price}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
                {isLoading && (
                  <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
