import React, { useState, useEffect, useRef } from 'react';
import {
  ShoppingCartIcon,
  HeartIcon,
  MenuIcon,
} from '@heroicons/react/outline';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faGoogle } from '@fortawesome/free-brands-svg-icons';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="pb-20">
      <nav className="flex mb-24 justify-between bg-gray-900 text-white w-screen fixed z-10">
        <div className="px-5 xl:px-12 py-6 flex w-full items-center">
          <Link to="/" className="text-3xl font-bold font-heading">
            <img
              src="/img/logo.png"
              alt="Plastic Store Logo"
              className="h-16 w-auto"
            />
          </Link>
          <CSSTransition
            in={isMenuOpen}
            timeout={300}
            classNames="slide"
            unmountOnExit
          >
            <ul
              ref={menuRef}
              className={`md:hidden absolute top-20 left-0 w-full flex flex-col font-semibold font-heading bg-gray-900 p-4 space-y-4 ${
                isMenuOpen ? 'block' : 'hidden'
              }`}
            >
              <li>
                <Link
                  to="/categorie/nuoviarrivi"
                  className="hover:text-gray-200"
                  onClick={closeMenu}
                >
                  {' '}
                  Nuovi arrivi
                </Link>
              </li>
              <li>
                <Link
                  to="/categorie/uomo"
                  className="hover:text-gray-200"
                  onClick={closeMenu}
                >
                  Uomo
                </Link>
              </li>
              <li>
                <Link
                  to="/categorie/donna"
                  className="hover:text-gray-200"
                  onClick={closeMenu}
                >
                  Donna
                </Link>
              </li>
              <li>
                <Link
                  to="/categorie/sneakers"
                  className="hover:text-gray-200"
                  onClick={closeMenu}
                >
                  Sneakers
                </Link>
              </li>
              <li>
                <Link
                  to="/contattaci"
                  className="hover:text-gray-200"
                  onClick={closeMenu}
                >
                  Contattaci
                </Link>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/plasticstore76/"
                  className="hover:text-gray-200"
                >
                  <FontAwesomeIcon icon={faInstagram} className="mr-2" />{' '}
                </a>
                <a
                  href="https://g.co/kgs/iQdrLXp"
                  className="hover:text-gray-200"
                >
                  <FontAwesomeIcon icon={faGoogle} className="mr-2" />
                </a>
              </li>
              
            </ul>
          </CSSTransition>

          <ul className="hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12">
            <li>
              <Link
                to="/categorie/nuoviarrivi"
                className="hover:text-gray-200"
                onClick={closeMenu}
              >
                Nuovi arrivi
              </Link>
            </li>
            <li>
              <Link
                to="/categorie/uomo"
                className="hover:text-gray-200"
                onClick={closeMenu}
              >
                Uomo
              </Link>
            </li>
            <li>
              <Link
                to="/categorie/donna"
                className="hover:text-gray-200"
                onClick={closeMenu}
              >
                Donna
              </Link>
            </li>
            <li>
              <Link
                to="/categorie/sneakers"
                className="hover:text-gray-200"
                onClick={closeMenu}
              >
                Sneakers
              </Link>
            </li>
          </ul>
          <div className="hidden xl:flex items-center space-x-5">
            <Link to="/cart" className="flex items-center hover:text-gray-200">
              <ShoppingCartIcon className="h-6 w-6" />
              <span className="flex absolute -mt-5 ml-4">
                <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-pink-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-pink-500"></span>
              </span>
            </Link>
          </div>
          <div className="hidden xl:flex items-center space-x-5 ml-5 ">
            {/* Aggiungi icona di Instagram */}
            <a
              href="https://www.instagram.com/plasticstore76/"
              className="hover:text-gray-200"
            >
              <FontAwesomeIcon icon={faInstagram} className="h-6 w-6" />
            </a>
            {/* Aggiungi icona di Google */}
            <a href="https://g.co/kgs/5fPYRBZ" className="hover:text-gray-200">
              <FontAwesomeIcon icon={faGoogle} className="h-6 w-6" />
            </a>
          </div>
        </div>
        <Link to="/cart" className="xl:hidden flex mr-6 items-center">
          <ShoppingCartIcon className="h-6 w-6 hover:text-gray-200" />
          <span className="flex absolute -mt-5 ml-4">
            <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-pink-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-pink-500"></span>
          </span>
        </Link>
        <button
          className="navbar-burger self-center mr-12 xl:hidden"
          onClick={toggleMenu}
        >
          <MenuIcon className="h-6 w-6 hover:text-gray-200" />
        </button>
      </nav>
    </div>
  );
};

export default Navbar;
