import React, { useState } from 'react';
import { app } from '../../services/firebase';

function EmailForm() {
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const sendEmail = async () => {
    setLoading(true);
    try {
      const userId = localStorage.getItem('userId'); // Assumendo che tu li abbia salvati nel localStorage

      const response = await fetch(
        'https://us-central1-plasticstorevelletri-6d5ca.cloudfunctions.net/sendEmail',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ to, subject, text, userId }), // Aggiungo userId se necessario
        }
      );

      const data = await response.json();

      if (data.success) {
        setSuccess(true);
      } else {
        setError('Failed to send email');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Send Email</h2>
      <input type="email" value={to} onChange={(e) => setTo(e.target.value)} placeholder="To" />
      <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Subject" />
      <textarea value={text} onChange={(e) => setText(e.target.value)} placeholder="Text Body"></textarea>
      <button onClick={sendEmail} disabled={loading || !to || !subject || !text }>Send Email</button>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {success && <p>Email sent successfully!</p>}
    </div>
  );
}

export default EmailForm;
