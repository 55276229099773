import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { Link } from 'react-router-dom';
import ProductCard from '../Common/ProductCard';
import Slider from './Slider';
import SliderL from './SliderL';

import Brands from './Brands';
import Banner from './Banner';
import './styles.css';
import Footer from '../Common/Footer';
import CookieBanner from '../Common/CookieBanner';
import Grid from './Grid';

const Homepage = () => {
  const [products, setProducts] = useState([]); // Aggiungi uno stato per i prodotti

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const db = getDatabase();
        const productsRef = ref(db, 'products');
        onValue(productsRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const productList = Object.keys(data).map((productId) => ({
              id: productId,
              ...data[productId],
            }));
            setProducts(productList);
          }
        });
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="flex flex-col min-h-screen w-screen">
      <CookieBanner />
      <main className="flex-grow bg-gradient-to-br from-gray-200 via-gray-300 to-gray-500 ">
        <section className="relative h-[calc(100vh-100px)] sm:h-auto  w-full">
          <div className="h-full sm:aspect-video">
            <video
              className="w-full h-full object-cover"
              autoPlay
              loop
              muted
              playsInline
              poster="./img/hero.png"
            >
              <source
                src="https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fherov.mp4?alt=media&token=103691e0-fb5d-44f8-85df-edd366e21677"
                type="video/mp4"
              />
            </video>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-center text-white">
                <div>
                  <Link to="/categorie/uomo">
                    <button
                      type="button"
                      className="mt-1.5 inline-block px-3 py-2 text-sm sm:text-xl font-medium uppercase tracking-wide text-white transform transition-transform hover:bg-gray-500 hover:text-gray-900 border border-white rounded-l-lg"
                    >
                      Uomo
                    </button>
                  </Link>

                  <Link to="/categorie/donna">
                    <button
                      type="button"
                      className="mt-1.5 inline-block px-3 py-2 text-sm sm:text-xl font-medium uppercase tracking-wide text-white transform transition-transform hover:bg-gray-500 hover:text-gray-900 border border-white rounded-r-lg"
                    >
                      Donna
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-container -mt-10 sm:mt-0  w-full z-10">
            <Slider />
            <SliderL/>
          </div>
        </section>
        {/* <Brands /> */}

       
        <div className="min-w-full mt-20">
          <Grid />
        </div>

        <div className="min-w-full mt-[-20px]">
          <Banner />
        </div>
        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">

          {products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
            /> 
          ))}
        </div> */}
        <Footer />
      </main>
    </div>
  );
};

export default Homepage;
