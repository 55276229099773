import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import { Link } from 'react-router-dom';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  deleteObject,
  getDownloadURL,
} from 'firebase/storage';


const Inventario = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const db = getDatabase();
  const storage = getStorage();


  useEffect(() => {
    const productsRef = ref(db, 'products');
    const unsubscribe = onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const productList = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setProducts(productList);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [db]);

// Filter products based on search term
const filteredProducts = products.filter((product) =>
  product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  product.SKU.toLowerCase().includes(searchTerm.toLowerCase()) ||
  product.id.toLowerCase().includes(searchTerm.toLowerCase()) // Aggiungi la ricerca per productId
);






const handleDeleteProduct = async (productId, imageUrl) => {
  try {
    // Rimuovi il prodotto dal Realtime Database
    const productRef = ref(db, `products/${productId}`);
    await remove(productRef);

    // Rimuovi l'immagine dallo Storage di Firebase
    const imageRef = storageRef(storage, imageUrl);
    await deleteObject(imageRef);

    toast.success('Prodotto eliminato con successo!');
  } catch (error) {
    console.error('Errore durante l\'eliminazione del prodotto:', error);
    toast.error('Si è verificato un errore durante l\'eliminazione del prodotto.');
  }
};

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="container mx-auto p-4">
                <ToastContainer />

      <h1 className="text-2xl font-bold mb-4">Elenco dei Prodotti</h1>
      <input
        type="text"
        placeholder="Cerca per nome/SKU"
        value={searchTerm}
        onChange={handleSearchTermChange}
        className="border border-gray-300 rounded px-3 py-1 mb-4"
      />
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th className="px-4 py-2">ID</th>
            <th className="px-4 py-2">Nome</th>
            <th className="px-4 py-2">Sku</th>
            <th className="px-4 py-2">Prezzo</th>
            <th className="px-4 py-2">Azioni</th>

          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((product) => (
            <tr key={product.id}>
              <td className="border px-4 py-2">{product.id}</td>
              <td className="border px-4 py-2">{product.name}</td>
              <td className="border px-4 py-2">{product.SKU}</td>
              <td className="border px-4 py-2">€ {product.price}</td>
              <td className="border px-2 py-2">
                <Link
                  to={`/edit-product/${product.id}`}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  mr-10 rounded"
                >
                  Modifica
                </Link>

                <button
                  onClick={() => handleDeleteProduct(product.id, product.imageUrl)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Elimina
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Inventario;
