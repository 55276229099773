import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, update, remove } from 'firebase/database';
import { FaTrashAlt } from 'react-icons/fa';

const AdminContactMessages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const messagesRef = ref(db, 'contacts');

    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messagesList = Object.entries(data).map(([key, value]) => ({ ...value, id: key }));
        setMessages(messagesList);
        markMessagesAsRead(messagesList);
      } else {
        setMessages([]);
      }
    });
  }, []);

  const markMessagesAsRead = async (messagesList) => {
    const db = getDatabase();
    const updates = {};

    messagesList.forEach((message) => {
      if (!message.read) {
        updates[`contacts/${message.id}/read`] = true;
      }
    });

    if (Object.keys(updates).length > 0) {
      await update(ref(db), updates);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    const db = getDatabase();
    try {
      await remove(ref(db, `contacts/${messageId}`));
      const updatedMessages = messages.filter((message) => message.id !== messageId);
      setMessages(updatedMessages);
    } catch (error) {
      console.error('Errore durante l\'eliminazione del messaggio:', error);
    }
  };

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4">Messaggi di Contatto</h1>
      {messages.length === 0 ? (
        <p>Nessun messaggio ricevuto.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {messages.map((message, index) => (
            <div key={index} className="bg-white shadow-md rounded-md p-4 relative">
              <button 
                className="absolute top-2 right-2  bg-red-500 rounded-full p-1 focus:outline-none"
                onClick={() => handleDeleteMessage(message.id)}
              >
                <FaTrashAlt className="text-white" />
              </button>
              <h3 className="text-lg font-semibold mb-2">
                Nome: {message.name} - Email: {message.email}
              </h3>
              <p className="text-gray-700">Messaggio: {message.message}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminContactMessages;
