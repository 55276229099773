import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, get, remove } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddModelForm = () => {
  const db = getDatabase();
  const [models, setModels] = useState([]);
  const [modelData, setModelData] = useState({
    name: '',
  });
  const [loading, setLoading] = useState(false); // Variabile di stato per il caricamento
  const [message, setMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModelData({ ...modelData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!modelData || !modelData.name) {
      // Se modelData o modelData.name è null o non definito, esce dalla funzione
      return;
    }

    setLoading(true); // Imposta lo stato di caricamento a true

    // Salva il nome del modello nel database Firebase
    const modelId = uuidv4();
    const modelsRef = ref(db, `models/${modelId}`);
    set(modelsRef, {
      name: modelData.name,
    })
      .then(() => {
        console.log('Modello aggiunto con successo!');
        toast.success('Modello aggiunto con successo!');
        setModelData({
          name: '',
        });

        // Salvataggio nel Realtime Database
        const dbRef = ref(db, 'models');
        set(dbRef.child(modelId), {
          name: modelData.name,
        });
      })
      .catch((error) => {
        console.error("Errore durante l'aggiunta del modello:", error);

      })
      .finally(() => {
        setLoading(false); // Imposta lo stato di caricamento a false dopo il completamento
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const modelsRef = ref(db, 'models');
        const snapshot = await get(modelsRef);
        if (snapshot.exists()) {
          const modelsList = [];
          snapshot.forEach((childSnapshot) => {
            const model = childSnapshot.val();
            model.id = childSnapshot.key;
            modelsList.push(model);
          });
          setModels(modelsList);
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleDeleteModel = async (modelId) => {
    try {
      const modelRef = ref(db, `models/${modelId}`);
      await remove(modelRef);
      setModels(models.filter(model => model.id !== modelId));
      toast.success('Modello eliminato con successo!');
    } catch (error) {
      console.error('Error deleting model:', error);
      toast.error('Si è verificato un errore durante l\'eliminazione del modello.');
    }
  };

  return (
    <div>
      <ToastContainer />
      {message && <p className="text-green-500">{message}</p>}
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-lg mx-auto mt-10 p-8 bg-white shadow-lg rounded-lg"
      >
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
            Nome Modello:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Inserisci il nome del modello"
            value={modelData.name}
            onChange={handleInputChange}
            className="input-field"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={loading}
        >
          {loading ? 'Sto aggiungendo il modello...' : 'Aggiungi Modello'}
        </button>
      </form>

      <h2>Elenco Modelli</h2>
      <ul>
        {models.map((model) => (
          <li key={model.id}>
            <div>
              <strong>{model.name}</strong>
            </div>
            <div>
              <button onClick={() => handleDeleteModel(model.id)}>
                Elimina Modello
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddModelForm;
