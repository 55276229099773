import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Importa useParams da react-router-dom
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, set, get } from 'firebase/database';

const OrderSuccess = () => {
  const [orderDetails, setOrderDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { orderId } = useParams(); // Ottieni l'orderId dalla barra degli indirizzi
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const db = getDatabase();

  const sendEmail = async () => {
    setLoading(true);
    try {
      const userId = localStorage.getItem('userId'); // Assumendo che tu li abbia salvati nel localStorage

      const response = await fetch(
        'https://us-central1-plasticstorevelletri-6d5ca.cloudfunctions.net/sendEmail',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ to, subject, text, userId }), // Aggiungo userId se necessario
        }
      );

      const data = await response.json();

      if (data.success) {
        setSuccess(true);
      } else {
        setError('Failed to send email');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const orderSnapshot = await get(
          ref(db, `users/${userId}/orders/${orderId}`)
        );
        if (orderSnapshot.exists()) {
          setOrderDetails(orderSnapshot.val());
        } else {
          setError('Order not found');
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
        setError('Failed to fetch order details');
      }
      setIsLoading(false);
    };

    const markOrderAsPaid = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const orderRef = ref(db, `users/${userId}/orders/${orderId}`);

        // Aggiorna il campo pagato a "SI"
        await set(orderRef, { ...orderDetails, pagato: 'SI' });

        console.log('Order marked as paid successfully!');
      } catch (error) {
        console.error('Error marking order as paid:', error);
        setError('Failed to mark order as paid');
      }
    };

    fetchOrder();

    const sendOrderConfirmationEmails = async () => {
      try {
        // Invia email all'amministratore
        const adminEmailResponse = await sendEmail({
          to: 'cartin.denis@gmail.com', // Indirizzo email dell'amministratore
          subject: 'Nuovo ordine ricevuto',
          text: `È stato ricevuto un nuovo ordine dall'utente ${
            orderDetails.name
          }. Dettagli ordine: `,
        });

        // Invia email all'utente
        const userEmailResponse = await sendEmail({
          to: orderDetails.email, // Indirizzo email dell'utente
          subject: 'Conferma ordine',
          text: `Grazie per il tuo ordine! Dettagli ordine: `,
        });

        console.log('Emails inviati con successo!');
      } catch (error) {
        console.error('Errore durante invio delle email:', error);
      }
    };

    if (!isLoading && !error) {
      markOrderAsPaid();
      sendOrderConfirmationEmails(); // Chiama la funzione per inviare le email
    }
  }, [db, orderId, orderDetails, isLoading, error]);

  return (
    <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
      <div className="flex justify-start item-start space-y-2 flex-col ">
        <h1 className="text-2xl lg:text-4xl font-semibold leading-7 lg:leading-9  text-gray-800">
          UHU! Ordine eseguito con successo!!
        </h1>
        {/* <p className="text-base font-medium leading-6 text-gray-600">21st Mart 2021 at 10:34 PM</p> */}
      </div>

      <div className="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6   ">
        <h3 className="text-xl font-semibold leading-5 text-gray-800">
          Spedizione
        </h3>
        <div className="flex justify-between items-start w-full">
          <div className="flex justify-center items-center space-x-4">
            <div class="w-8 h-8">
              <img
                class="w-full h-full"
                alt="logo"
                src="https://i.ibb.co/L8KSdNQ/image-3.png"
              />
            </div>
            <div className="flex flex-col justify-start items-center">
              <p className="text-lg leading-6 font-semibold text-gray-800">
                DPD Delivery
                <br />
                <span className="font-normal">
                  Il tuo prodotto arriverà entro 4/5 giorni
                </span>
              </p>
            </div>
          </div>
          <p className="text-lg font-semibold leading-6 text-gray-800">€8.00</p>
        </div>
        {/* <div className="w-full flex justify-center items-center">
                                <button className="hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base font-medium leading-4 text-white">View Carrier Details</button>
                            </div> */}
      </div>
      <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch  w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
        <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
          <div className="flex flex-col justify-start items-start bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
            {orderDetails.products && orderDetails.products.length > 0 ? (
              orderDetails.products.map((product, index) => (
                <div
                  key={index}
                  className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full pb-8 space-y-4 md:space-y-0"
                >
                  <div className="w-full flex flex-col justify-start items-start space-y-8">
                    <h3 className="text-xl xl:text-2xl font-semibold leading-6 text-gray-800">
                      {product.name}
                    </h3>
                    <div className="flex justify-start items-start flex-col space-y-2">
                      <p className="text-sm leading-none text-gray-800">
                        <span className="text-gray-300">Taglia: </span>
                        {product.size}
                      </p>
                      <p className="text-sm leading-none text-gray-800">
                        <span className="text-gray-300">Colore: </span>
                        {product.color}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between space-x-8 items-start w-full">
                    <p className="text-base xl:text-lg font-semibold leading-6 text-gray-800">
                      €{product.price}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p>No products found</p>
            )}
          </div>
          <div className="flex justify-center md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
            <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6   ">
              <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6">
                <h3 className="text-xl font-semibold leading-5 text-gray-800">
                  Dettagli ordine
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                  <div className="flex justify-between w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Subtotale
                    </p>
                    <p className="text-base leading-4 text-gray-600">
                      €{orderDetails.total}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Spedizione
                    </p>
                    <p className="text-base leading-4 text-gray-600">€8.00</p>{' '}
                    {/* Assuming fixed shipping cost */}
                  </div>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-base font-semibold leading-4 text-gray-800">
                    Totale
                  </p>
                  <p className="text-base font-semibold leading-4 text-gray-600">
                    €{orderDetails.total + 8}
                  </p>{' '}
                  {/* Total including shipping */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 w-full xl:w-96 flex justify-between items-center md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col ">
          <h3 className="text-xl font-semibold leading-5 text-gray-800">
            Dettagli acquirente
          </h3>
          <div className="flex  flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0 ">
            <div className="flex flex-col justify-start items-start flex-shrink-0">
              <div className="flex justify-center  w-full  md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                <div className=" flex justify-start items-start flex-col space-y-2">
                  <p className="text-base font-semibold leading-4 text-left text-gray-800">
                    {orderDetails.name}
                  </p>
                </div>
              </div>

              <div className="flex justify-center  md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
                    stroke="#1F2937"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 7L12 13L21 7"
                    stroke="#1F2937"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="cursor-pointer text-sm leading-5 text-gray-800">
                  {orderDetails.email}
                </p>
              </div>
            </div>
            <div className="flex justify-between xl:h-full  items-stretch w-full flex-col mt-6 md:mt-0">
              <div className="flex justify-center md:justify-start xl:flex-col flex-col md:space-x-6 lg:space-x-8 xl:space-x-0 space-y-4 xl:space-y-12 md:space-y-0 md:flex-row  items-center md:items-start ">
                <div className="flex justify-center md:justify-start  items-center md:items-start flex-col space-y-4 xl:mt-8">
                  <p className="text-base font-semibold leading-4 text-center md:text-left text-gray-800">
                    Indirizzo di spedizione
                  </p>
                  <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                    Indririzzo: {orderDetails.address}
                  </p>
                  <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                    Città: {orderDetails.city}
                  </p>
                  <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                    CAP: {orderDetails.postalCode}
                  </p>
                </div>
                {/* <div className="flex justify-center md:justify-start  items-center md:items-start flex-col space-y-4 ">
                                    <p className="text-base font-semibold leading-4 text-center md:text-left text-gray-800">Billing Address</p>
                                    <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">180 North King Street, Northhampton MA 1060</p>
                                </div> */}
              </div>
              {/* <div className="flex w-full justify-center items-center md:justify-start md:items-start">
                                <button className="mt-6 md:mt-0 py-5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 border border-gray-800 font-medium w-96 2xl:w-full text-base leading-4 text-gray-800">Edit Details</button>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
