import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, get, remove } from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  deleteObject,
  getDownloadURL,
  listAll,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChromePicker } from 'react-color';

const BannerManagement = () => {
  const db = getDatabase();
  const storage = getStorage();
  const [homeBanners, setHomeBanners] = useState([]);
  const [categoryBanners, setCategoryBanners] = useState([]);
  const [bannerData, setBannerData] = useState({
    image: null,
    color: '#000000',
    text: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setBannerData({ ...bannerData, image: file });
  };

  const handleColorChange = (color) => {
    setBannerData({ ...bannerData, color: color.hex });
  };

  const handleTextChange = (e) => {
    setBannerData({ ...bannerData, text: e.target.value });
  };

  const handleSubmit = async (e, type) => {
    e.preventDefault();

    if (!bannerData.image && type === 'home') {
      return;
    }

    if (!bannerData.color && !bannerData.text && type === 'categorie') {
      return;
    }

    setLoading(true);

    try {
      const bannerId = uuidv4();
      const bannerRef = ref(db, `banners/${type}/${bannerId}`);
      const snapshot = await get(bannerRef);
      if (snapshot.exists()) {
        const oldImageUrl = snapshot.val().imageUrl;
        const oldImageRef = storageRef(storage, oldImageUrl);
        await deleteObject(oldImageRef);
      }

      if (type === 'home') {
        const imageRef = storageRef(
          storage,
          `banners/${type}/${bannerData.image.name}`
        );
        await uploadBytes(imageRef, bannerData.image);
        const imageUrl = await getDownloadURL(imageRef);

        await set(bannerRef, {
          imageUrl,
          imageName: bannerData.image.name,
        });
      } else {
        await set(bannerRef, {
          color: bannerData.color,
          text: bannerData.text,
        });
      }

      setMessage('Banner aggiunto con successo!');
      setBannerData({ image: null, color: '#000000', text: '' });
    } catch (error) {
      console.error("Errore durante l'aggiunta del banner:", error);
      setMessage('Si è verificato un errore durante l\'aggiunta del banner. Si prega di riprovare.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const homeBannersListRef = ref(db, 'banners/home');
        const homeSnapshot = await get(homeBannersListRef);
        if (homeSnapshot.exists()) {
          const homeBannersList = [];
          homeSnapshot.forEach((childSnapshot) => {
            const banner = childSnapshot.val();
            banner.id = childSnapshot.key;
            homeBannersList.push(banner);
          });
          setHomeBanners(homeBannersList);
        }

        const categoryBannersListRef = ref(db, 'banners/categorie');
        const categorySnapshot = await get(categoryBannersListRef);
        if (categorySnapshot.exists()) {
          const categoryBannersList = [];
          categorySnapshot.forEach((childSnapshot) => {
            const banner = childSnapshot.val();
            banner.id = childSnapshot.key;
            categoryBannersList.push(banner);
          });
          setCategoryBanners(categoryBannersList);
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  const handleDeleteBanner = async (type, bannerId, imageName) => {
    try {
      const bannerRef = ref(db, `banners/${type}/${bannerId}`);
      await remove(bannerRef);

      if (type === 'home') {
        const imageUrl = `banners/${type}/${imageName}`;
        await deleteObject(storageRef(storage, imageUrl));
      }

      if (type === 'home') {
        setHomeBanners(homeBanners.filter((banner) => banner.id !== bannerId));
      } else {
        setCategoryBanners(
          categoryBanners.filter((banner) => banner.id !== bannerId)
        );
      }

      toast.success('Banner eliminato con successo!');
    } catch (error) {
      console.error('Error deleting banner:', error);
      toast.error('Si è verificato un errore durante l\'eliminazione del banner.');
    }
  };

  return (
    <div>
      <ToastContainer />
      {message && <p className="text-green-500">{message}</p>}
      <form
        onSubmit={(e) => handleSubmit(e, 'home')}
        className="w-full max-w-lg mx-auto mt-10 p-8 bg-white shadow-lg rounded-lg"
      >
        <div className="mb-4">
          <label
            htmlFor="homeBanner"
            className="block text-gray-700 font-bold mb-2"
          >
            Banner Home:
          </label>
          <input
            type="file"
            id="homeBanner"
            name="homeBanner"
            accept="image/*"
            onChange={handleImageChange}
            className="input-field"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={loading}
        >
          {loading ? 'Sto aggiungendo il banner...' : 'Aggiungi Banner Home'}
        </button>
      </form>

      <h2>Banner Home</h2>
      <ul>
        {homeBanners.map((banner) => (
          <li key={banner.id}>
            <div>
              <img src={banner.imageUrl} alt="Banner Home" />
            </div>
            <div>
              <button
                onClick={() =>
                  handleDeleteBanner('home', banner.id, banner.imageName)
                }
              >
                Elimina Banner
              </button>
            </div>
          </li>
        ))}
      </ul>

      <form
  onSubmit={(e) => handleSubmit(e, 'categorie')}
  className="w-full max-w-lg mx-auto mt-10 p-8 bg-white shadow-lg rounded-lg"
>
  <div className="mb-4">
    <label
      htmlFor="categoryBannerText"
      className="block text-gray-700 font-bold mb-2"
    >
      Testo Banner Categorie:
    </label>
    <input
      type="text"
      id="categoryBannerText"
      name="categoryBannerText"
      value={bannerData.text}
      onChange={handleTextChange}
      className="input-field"
    />
  </div>
  <div className="mb-4">
    <label
      htmlFor="categoryBannerColor"
      className="block text-gray-700 font-bold mb-2"
    >
      Colore Banner Categorie:
    </label>
    <div className="relative">
      <button
        type="button"
        className="bg-gray-200 text-gray-700 font-semibold py-2 px-4 rounded shadow-md"
        onClick={() => setShowColorPicker(!showColorPicker)}
        style={{ backgroundColor: bannerData.color }}
      >
        Seleziona Colore
      </button>
      {showColorPicker && (
        <div className="absolute z-10">
          <ChromePicker
            color={bannerData.color}
            onChange={handleColorChange}
            disableAlpha={true}
          />
        </div>
      )}
    </div>
  </div>
  <button
    type="submit"
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    disabled={loading}
  >
    {loading ? 'Sto aggiungendo il banner...' : 'Aggiungi Banner Categorie'}
  </button>
</form>

      <h2>Banner Categorie</h2>
      <ul>
        {categoryBanners.map((banner) => (
          <li key={banner.id}>
            <div
              className="flex items-center justify-center h-32 mb-4"
              style={{ backgroundColor: banner.color }}
            >
              <span className="text-white font-bold text-2xl">{banner.text}</span>
            </div>
            <div>
              <button
                onClick={() =>
                  handleDeleteBanner('categorie', banner.id)
                }
              >
                Elimina Banner
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BannerManagement;