import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageSlider = () => {
  const images = [
    "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fa.png?alt=media&token=83c9440a-f1d9-4b5d-89af-d927b638f6dc",
    "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fb.png?alt=media&token=7fa4d2cf-3594-4283-b824-7411599f5202",
    "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fc.png?alt=media&token=160deb35-7968-450e-ac91-9ee1cd327358",
    "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fd.png?alt=media&token=b81be90b-5b54-4c52-868a-5fa597a74950",
    "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fe.png?alt=media&token=79f77241-30c4-49a2-8a81-7d2c99d71046",
    "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Ff.png?alt=media&token=722cd8de-ed42-4fa6-9707-2ceb93e01d26",
    "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fg.png?alt=media&token=b42ac210-4b77-4d15-a5dc-72beebe17786",
    "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fh.png?alt=media&token=962b124a-5254-4b3f-ba9f-f0417a5f0e4d",
    // "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fi.png?alt=media&token=6bd5f737-172a-4f55-85b6-23e503d5eef6",
    // "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fl.png?alt=media&token=13d0220b-98dd-436c-80a3-47daf5bc671e",
    // "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fm.png?alt=media&token=ab86c3e0-5537-44ba-98ec-e3bd084502d7",
    // "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fn.png?alt=media&token=1f6c8ea9-0772-4fda-8496-e6b93d6e303f",
    // "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fo.png?alt=media&token=8c1d07bd-7903-43e4-8140-9eb0e4c0e1eb",
    // "https://firebasestorage.googleapis.com/v0/b/plasticstorevelletri-6d5ca.appspot.com/o/ecommerce%2Fsponsor%2Fp.png?alt=media&token=8080d051-096e-4f0a-ac3e-19c993d65d27"
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ]
  };

  return (
    <div className="slider-container" style={{marginTop:'-7px'}}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Immagine ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
