import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, get } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';

const AddProductForm = () => {
  const db = getDatabase();
  const storage = getStorage();
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false); // Variabile di stato per il caricamento

  const [productData, setProductData] = useState({
    name: '',
    description: '',
    price: 0,
    categoria: [],
    sizes: [],
    colors: [],
    models: [],
    brands: [],
    discount: '',
    SKU: '',
    nuovoArrivo: [],
  });
  const [imageFiles, setImageFiles] = useState([]);

  const [dynamicOptions, setDynamicOptions] = useState({
    sizes: ['S', 'M', 'L'],
    colors: ['Rosso', 'Blu', 'Verde'],
    models: [],
    brands: [],
  });
  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    setImageFiles((prevImageFiles) => {
      const newImageFiles = [...prevImageFiles];
      newImageFiles[index] = file;
      return newImageFiles;
    });
  };

  const handleAddImage = () => {
    if (imageFiles.length < 5) {
      setImageFiles([...imageFiles, null]);
    }
  };

  const handleRemoveImage = (index) => {
    const newImageFiles = [...imageFiles];
    newImageFiles.splice(index, 1);
    setImageFiles(newImageFiles);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  const handleDynamicOptionsChange = (optionType, selectedOptions) => {
    setProductData({ ...productData, [optionType]: selectedOptions });
  };

  const handleDynamicOptionsToggle = (optionType, option) => {
    setProductData((prevData) => ({
      ...prevData,
      [optionType]: prevData[optionType].includes(option)
        ? prevData[optionType].filter((o) => o !== option)
        : [...prevData[optionType], option],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!productData || imageFiles.length === 0) {
      // Se productData o non ci sono immagini selezionate, esce dalla funzione
      return;
    }
    setLoading(true); // Imposta lo stato di caricamento a true
  
    try {
      const productId = uuidv4();
      const imageUrlsPromises = imageFiles.map(async (imageFile, index) => {
        if (imageFile) {
          const imageRef = storageRef(
            storage,
            `products/${productId}/image_${index}_${imageFile.name}`
          );
          await uploadBytes(imageRef, imageFile);
          const imageUrl = await getDownloadURL(imageRef);
          return imageUrl;
        }
        return ''; // Restituisci una stringa vuota invece di null
      });
  
      const imageUrls = await Promise.all(imageUrlsPromises);
  
      // Salva i dati del prodotto nel Realtime Database
      const databaseRef = ref(db, `products/${productId}`);
      await set(databaseRef, {
        ...productData,
        imageUrls,
        id: productId // Utilizza solo l'ID generato per il prodotto
      });
  
      console.log('Prodotto aggiunto con successo!');
  
      // Resetta lo stato del form dopo l'invio
      setProductData({
        name: '',
        description: '',
        price: 0,
        categoria: [],
        sizes: [],
        colors: [],
        models: [],
        brands: [],
        discount: '',
        SKU: '',
        nuovoArrivo: [],
      });
      setImageFiles([]); // Resetta l'array di immagini
  
      // Mostra un toast di successo
      toast.success('Prodotto aggiunto con successo!');
    } catch (error) {
      console.error('Errore durante l\'aggiunta del prodotto:', error);
      // Mostra un toast di errore
      toast.error('Si è verificato un errore durante l\'aggiunta del prodotto.');
    } finally {
      setLoading(false); // Imposta lo stato di caricamento a false dopo il completamento
    }
  };

  useEffect(() => {
    const fetchBrandsAndModels = async () => {
      try {
        const brandsRef = ref(db, 'brands');
        const brandsSnapshot = await get(brandsRef);
        if (brandsSnapshot.exists()) {
          const brandsData = brandsSnapshot.val();
          const brands = Object.keys(brandsData).map(
            (brandId) => brandsData[brandId].name
          );
          setDynamicOptions((prevState) => ({ ...prevState, brands }));
        }

        const modelsRef = ref(db, 'models');
        const modelsSnapshot = await get(modelsRef);
        if (modelsSnapshot.exists()) {
          const modelsData = modelsSnapshot.val();
          const models = Object.keys(modelsData).map(
            (modelId) => modelsData[modelId].name
          );
          setDynamicOptions((prevState) => ({ ...prevState, models }));
        }

        const sizesRef = ref(db, 'sizes');
        const sizesSnapshot = await get(sizesRef);
        if (sizesSnapshot.exists()) {
          const sizesData = sizesSnapshot.val();
          const sizes = Object.keys(sizesData).map(
            (sizeId) => sizesData[sizeId].name
          );
          setDynamicOptions((prevState) => ({ ...prevState, sizes }));
        }
      } catch (error) {
        console.error('Error fetching brands, models, and sizes:', error);
      }
    };

    fetchBrandsAndModels();
  }, [db]);
  return (
    <div>
      <ToastContainer />

      <form
        onSubmit={handleSubmit}
        className="w-full max-w-lg mx-auto  p-8 bg-white shadow-lg rounded-lg"
      >
        <div className="mb-4 mt-24">
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
            Nome:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Inserisci il nome del prodotto"
            value={productData.name}
            onChange={handleInputChange}
            className="input-field"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="image" className="block text-gray-700 font-bold mb-2">
            Immagine:
          </label>
          {imageFiles.map((_, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="file"
                id={`image-${index}`}
                name={`image-${index}`}
                onChange={(e) => handleImageChange(e, index)}
                className="input-field mr-2"
              />
              <button
                type="button"
                onClick={() => handleRemoveImage(index)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
              >
                Rimuovi
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddImage}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            +
          </button>
        </div>
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block text-gray-700 font-bold mb-2"
          >
            Descrizione:
          </label>
          <textarea
            id="description"
            name="description"
            placeholder="Inserisci la descrizione del prodotto"
            value={productData.description}
            onChange={handleInputChange}
            className="input-field"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="price" className="block text-gray-700 font-bold mb-2">
            Prezzo:
          </label>
          <input
            type="number"
            id="price"
            name="price"
            placeholder="Inserisci il prezzo del prodotto"
            value={productData.price}
            onChange={handleInputChange}
            className="input-field"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="NuovoArrivo"
            className="block text-gray-700 font-bold mb-2"
          >
            Nuovo Arrivo:
          </label>
          <select
            id="NuovoArrivo"
            name="nuovoArrivo"
            multiple
            value={productData.nuovoArrivo}
            onChange={(e) =>
              handleDynamicOptionsChange(
                'nuovoArrivo',
                Array.from(e.target.selectedOptions, (option) => option.value)
              )
            }
            className="input-field"
          >
            <option value="Sí">Sí</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="mb-4">
          <label
            htmlFor="categoria"
            className="block text-gray-700 font-bold mb-2"
          >
            Genere:
          </label>
          <div>
            {['Uomo', 'Donna'].map((option) => (
              <span
                key={option}
                className={`inline-block mr-2 mb-2 px-3 py-1 rounded-md cursor-pointer ${
                  productData.categoria.includes(option)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
                onClick={() => handleDynamicOptionsToggle('categoria', option)}
              >
                {option}
              </span>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="sizes" className="block text-gray-700 font-bold mb-2">
            Taglie:
          </label>
          <div>
            {dynamicOptions.sizes.map((size) => (
              <span
                key={size}
                className={`inline-block mr-2 mb-2 px-3 py-1 rounded-md cursor-pointer ${
                  productData.sizes.includes(size)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
                onClick={() => handleDynamicOptionsToggle('sizes', size)}
              >
                {size}
              </span>
            ))}
          </div>
        </div>

        {/* Aggiungi campo per selezionare i colori */}
        <div className="mb-4">
          <label
            htmlFor="colors"
            className="block text-gray-700 font-bold mb-2"
          >
            Colori:
          </label>
          <div>
            {dynamicOptions.colors.map((color) => (
              <span
                key={color}
                className={`inline-block mr-2 mb-2 px-3 py-1 rounded-md cursor-pointer ${
                  productData.colors.includes(color)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
                onClick={() => handleDynamicOptionsToggle('colors', color)}
              >
                {color}
              </span>
            ))}
          </div>
        </div>

        {/* Aggiungi campo per selezionare i modelli */}
        <div className="mb-4">
          <label
            htmlFor="models"
            className="block text-gray-700 font-bold mb-2"
          >
            Modello:
          </label>
          <div>
            {dynamicOptions.models.map((model) => (
              <span
                key={model}
                className={`inline-block mr-2 mb-2 px-3 py-1 rounded-md cursor-pointer ${
                  productData.models.includes(model)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
                onClick={() => handleDynamicOptionsToggle('models', model)}
              >
                {model}
              </span>
            ))}
          </div>
        </div>

        {/* Aggiungi campo per selezionare i brand */}
        <div className="mb-4">
          <label
            htmlFor="brands"
            className="block text-gray-700 font-bold mb-2"
          >
            Brand:
          </label>
          <div>
            {dynamicOptions.brands.map((brand) => (
              <span
                key={brand}
                className={`inline-block mr-2 mb-2 px-3 py-1 rounded-md cursor-pointer ${
                  productData.brands.includes(brand)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
                onClick={() => handleDynamicOptionsToggle('brands', brand)}
              >
                {brand}
              </span>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <label
            htmlFor="discount"
            className="block text-gray-700 font-bold mb-2"
          >
            Sconto:
          </label>
          <input
            type="text"
            id="discount"
            name="discount"
            placeholder="Inserisci lo sconto del prodotto"
            value={productData.discount}
            onChange={handleInputChange}
            className="input-field"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="SKU" className="block text-gray-700 font-bold mb-2">
            SKU:
          </label>
          <input
            type="text"
            id="SKU"
            name="SKU"
            placeholder="Inserisci lo SKU del prodotto"
            value={productData.SKU}
            onChange={handleInputChange}
            className="input-field"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={loading} // Disabilita il pulsante durante il caricamento
        >
          {loading ? 'Sto aggiungendo il prodotto...' : 'Aggiungi Prodotto'}
        </button>
      </form>
    </div>
  );
};

export default AddProductForm;
