import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';

const ColorBanner = () => {
  const [colorBanners, setColorBanners] = useState([]);

  useEffect(() => {
    const fetchColorBanners = async () => {
      try {
        const db = getDatabase();
        const colorBannersRef = ref(db, 'banners/categorie');
        onValue(colorBannersRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const colorBannersList = Object.keys(data).map((bannerId) => ({
              id: bannerId,
              ...data[bannerId],
            }));
            setColorBanners(colorBannersList);
          }
        });
      } catch (error) {
        console.error('Error fetching color banners:', error);
      }
    };

    fetchColorBanners();
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-7">
      {colorBanners.map((banner) => (
        <div
          key={banner.id}
          className="relative w-screen block h-10 overflow-hidden"
        >
          <div
            className="flex items-center justify-center h-full"
            style={{ backgroundColor: banner.color }}
          >
            <span className="text-white font-bold text-2xl">{banner.text}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ColorBanner;