import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getDatabase, ref, push, set } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid'; // Importa la funzione v4 di UUID

const ProductCard = ({ product }) => {
  const [addedToCart, setAddedToCart] = useState(false);

  const handleAddToCart = (product) => {
    if (!product.id) {
      console.error('Product ID is not defined.');
      return;
    }

    const userId = localStorage.getItem('userId');
    const db = getDatabase();
    const cartItemsRef = ref(db, `users/${userId}/cartItems`);
    const newCartItemRef = push(cartItemsRef);
    const newUUID = uuidv4();

    set(ref(db, `users/${userId}/cartItems/${newUUID}`), {
      id: newUUID,
      name: product.name,
      price: product.price,
      img: product.imageUrl,
      quantity: 1,
    })
      .then(() => {
        console.log('Product added to cart:', product.name);
        setAddedToCart(true); // Imposta lo stato a true dopo aver aggiunto il prodotto al carrello
      })
      .catch((error) => {
        console.error('Error adding product to cart:', error);
      });
  };

  return (
    <>
      {/* <div className="mx-auto">
      <div className="bg-gray-100 mb-3 rounded-2xl p-6 cursor-pointer hover:-translate-y-2 transition-all relative lg:h-[55vh] sm:h-[45vh] sm:w-[45vh]">
        <Link to={`/${product.id}`} className="block">
          <img
            src={product.imageUrl}
            alt={product.name}
            className="fill-gray-800 inline-block object-cover rounded-lg h-[200px] w-[40vh]"
          />
        </Link>
        <div className="p-4">
          <Link to={`/${product.id}`} className="block">
            <h3 className="text-gray-900 font-semibold mb-2 text-sm sm:text-base">
              {product.name}
            </h3>
            <p className="text-gray-900">€ {product.price}</p>
          </Link>
          {!addedToCart ? (
            <button
              onClick={() => handleAddToCart(product)}
              className="w-full mt-6 px-4 py-3 bg-[#333] hover:bg-[#222] text-white rounded-full"
            >
              Aggiungi al carrello
            </button>
            
          ) : (
            <p className="mt-4 text-blue-900">
              Prodotto aggiunto nel carrello!
            </p>
          )}
        </div>
      </div>
    </div> */}

      <div class="relative m-2 flex w-full max-w-xl flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md">
        <a
          class="relative mx-3 mt-3 flex h-60 overflow-hidden rounded-xl"
          href="#"
        >
          <img class="object-cover" src={product.imageUrls} alt={product.name} />
          <span class="absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white">
            Disponibile ora!
          </span>
        </a>
        <div class="mt-4 px-5 pb-5">
          <a href="#">
            <h5 class="text-xl tracking-tight text-slate-900">
              {product.name}
            </h5>
          </a>
          <div class="mt-2 mb-5 flex items-center justify-between">
            <p>
              <span class="text-3xl font-bold text-slate-900">
                € {product.price}
              </span>
              {/* <span class="text-sm text-slate-900 line-through">$699</span> */}
            </p>
            <div class="flex items-center">
              <svg
                aria-hidden="true"
                class="h-5 w-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                class="h-5 w-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                class="h-5 w-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                class="h-5 w-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                class="h-5 w-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              {/* <span class="mr-2 ml-3 rounded bg-yellow-200 px-2.5 py-0.5 text-xs font-semibold">
                5.0
              </span> */}
            </div>
          </div>
          <a
            href="#"
            class="flex items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            <button>
              <Link to={`/${product.id}`} className="block">
                Dettagli prodotto
              </Link>
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
