import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import { ToastContainer, toast } from 'react-toastify';



const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [show, setShow] = useState(false);


  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const db = getDatabase();
        const cartItemsRef = ref(db, `users/${userId}/cartItems`);
        onValue(cartItemsRef, (snapshot) => {
          const cartItemsData = snapshot.val();
          if (cartItemsData) {
            const cartItemsArray = Object.values(cartItemsData);
            setCartItems(cartItemsArray);
            const totalPrice = cartItemsArray.reduce(
              (acc, item) => acc + item.price * item.quantity,
              0
            );
            setTotal(totalPrice);
          }
        });
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    };

    fetchCartItems();
  }, []);

  const handleDelete = (itemId) => {
    const userId = localStorage.getItem('userId');
    const db = getDatabase();
    const itemRef = ref(db, `users/${userId}/cartItems/${itemId}`);
    remove(itemRef)
      .then(() => {
        setCartItems(cartItems.filter(item => item.id !== itemId));
        console.log('Elemento eliminato con successo dal carrello.');
        toast.success('Prodotto eliminato con successo');

      })
      .catch((error) => {
        console.error('Si è verificato un errore durante l\'eliminazione dell\'elemento dal carrello:', error);
      });
  };

  return (
    <div className="max-w-4xl mx-auto py-8">
     <ToastContainer />
      <h2 className="text-3xl font-bold mb-6">Carrello</h2>
      <ul className="divide-y divide-gray-200">
        {cartItems.map((item) => (
          <li
            key={item.id}
            className="py-4 flex items-center justify-between"
          >
            <div className="flex items-center">
              <img
                src={item.img}
                alt={item.name}
                className="w-16 h-16 object-cover rounded-md mr-4"
              />
              <div>
                <h3 className="text-lg font-semibold">{item.name}</h3>
                <p className="text-gray-600">
                  SKU: {item.sku} - ${item.price} x {item.quantity}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <p className="text-lg font-semibold">
              € {(item.price * item.quantity).toFixed(2)}
              </p>
              <button
                onClick={() => handleDelete(item.id)}
                className="ml-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Elimina
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className="mt-8 flex items-center justify-between">
        <p className="text-2xl font-bold">Totale:</p>
        <p className="text-2xl font-bold">€ {total.toFixed(2)}</p>
      </div>
      <div className="mt-8">
  <button
    onClick={() => {
      if (cartItems.length > 0) {
        window.location.href = '/checkout'; // Naviga alla pagina di checkout
      }
    }}
    className={`inline-block px-6 py-3 ${cartItems.length === 0 ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-800 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700'} text-white rounded-md transition-colors duration-300`}
    disabled={cartItems.length === 0}
  >
    Procedi al Checkout
  </button>
</div>

    </div>

    
  );
};

export default Cart;
