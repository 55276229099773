import React, { useState } from 'react';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import { getDatabase, ref, push } from 'firebase/database';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    read : false,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const db = getDatabase();
      const contactsRef = ref(db, 'contacts');
      await push(contactsRef, formData);
      setFormData({ name: '', email: '', message: '' });
      alert('Messaggio inviato con successo!');
    } catch (error) {
      console.error("Errore durante l'invio del messaggio:", error);
      alert(
        "Si è verificato un errore durante l'invio del messaggio. Riprova più tardi."
      );
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto py-12 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="container mx-auto px-4 py-8">
            <h2 className="text-2xl font-bold mb-4">Plastic Store</h2>
            {/* Utilizza le icone di Heroicons accanto alle informazioni di contatto */}
            <div className="flex items-center mb-2">
              <MailIcon className="h-5 w-5 mr-2 text-gray-500" />
              <p className="text-lg">Email: info@example.com</p>
            </div>
            <div className="flex items-center mb-2">
              <PhoneIcon className="h-5 w-5 mr-2 text-gray-500" />
              <p className="text-lg">Telefono: 123-456-7890</p>
            </div>
            <div className="flex items-center mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2 text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 2a8 8 0 0 0-8 8c0 4.418 3.582 8 8 8s8-3.582 8-8a8 8 0 0 0-8-8zm0 14c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6zm1-7.375a.625.625 0 1 1-1.25 0 .625.625 0 0 1 1.25 0zM10 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
              </svg>
              <p className="text-lg">
                Google Business:{' '}
                <a href="https://g.co/kgs/5fPYRBZ">Plastic Store</a>
              </p>
            </div>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2 text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 2a8 8 0 0 0-8 8c0 4.418 3.582 8 8 8s8-3.582 8-8a8 8 0 0 0-8-8zm0 14c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6zm-1-2.5a.5.5 0 0 1-.5-.5V9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-2zm1-9a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
              </svg>
              <p className="text-lg">
                Instagram:{' '}
                <a href="https://www.instagram.com/plasticstore76/">
                  @plasticstore
                </a>
              </p>
            </div>
            {/* Inserisci qui il componente della mappa */}
            <div className="mt-8">
              {/* Inserisci qui il codice per incorporare la mappa */}
              <iframe
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11917.61720841285!2d12.780158!3d41.690207!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132582c7b4400a2d%3A0x24c2961abd653343!2sPlasticstore%20Velletri!5e0!3m2!1sit!2sus!4v1712343605451!5m2!1sit!2sus"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div>
            <h1 className="text-3xl font-semibold text-gray-800 mb-6">
              Contattaci
            </h1>
            <div className="max-w-lg">
              <p className="text-gray-600 mb-4">
                Hai domande o commenti? Non esitare a contattarci. Compila il
                modulo qui sotto e ti risponderemo al più presto possibile.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-gray-700 mb-1">
                    Nome
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="message" className="block text-gray-700 mb-1">
                    Messaggio
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="4"
                    className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                  Invia
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
