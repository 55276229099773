import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCog, FaBox, FaClipboardList, FaTags, FaImage, FaWarehouse, FaUsers } from 'react-icons/fa';
import { getDatabase, ref, onValue } from 'firebase/database';

const AdminDashboard = () => {

  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const db = getDatabase();
    const messagesRef = ref(db, 'contacts');

    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messagesList = Object.values(data);
        const unreadMessages = messagesList.filter(message => !message.read);
        setUnreadCount(unreadMessages.length);
      } else {
        setUnreadCount(0);
      }
    });
  }, []);
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-semibold mb-4">Admin Dashboard</h2>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
        <Link to="/admin/manage-products" className="dashboard-link">
          <div className="dashboard-item bg-white p-4 rounded-lg shadow-md flex items-center">
            <FaBox className="text-3xl mr-2 text-gray-600" />
            Aggiungi prodotto
          </div>
        </Link>
        <Link to="/admin/inventario" className="dashboard-link">
          <div className="dashboard-item bg-white p-4 rounded-lg shadow-md flex items-center">
            <FaWarehouse className="text-3xl mr-2 text-gray-600" />
            Gestisci l'inventario
          </div>
        </Link>
        <Link to="/admin/manage-orders" className="dashboard-link">
          <div className="dashboard-item bg-white p-4 rounded-lg shadow-md flex items-center">
            <FaClipboardList className="text-3xl mr-2 text-gray-600" />
            Gestisci gli ordini
          </div>
        </Link>
        <Link to="/admin/brand-manager" className="dashboard-link">
          <div className="dashboard-item bg-white p-4 rounded-lg shadow-md flex items-center">
            <FaTags className="text-3xl mr-2 text-gray-600" />
            Gestisci i Brand
          </div>
        </Link>
        <Link to="/admin/model-manager" className="dashboard-link">
          <div className="dashboard-item bg-white p-4 rounded-lg shadow-md flex items-center">
            <FaCog className="text-3xl mr-2 text-gray-600" />
            Gestisci i Modelli
          </div>
        </Link>
        <Link to="/admin/sizemanager" className="dashboard-link">
          <div className="dashboard-item bg-white p-4 rounded-lg shadow-md flex items-center">
            <FaCog className="text-3xl mr-2 text-gray-600" />
            Gestisci le taglie
          </div>
        </Link>
        <Link to="/admin/banner-manager" className="dashboard-link">
          <div className="dashboard-item bg-white p-4 rounded-lg shadow-md flex items-center">
            <FaImage className="text-3xl mr-2 text-gray-600" />
            Gestisci i Banner
          </div>
        </Link>
        <Link to="/admin/contacts" className="dashboard-link relative">
        <div className="dashboard-item bg-white p-4 rounded-lg shadow-md flex items-center">
          <FaUsers className="text-3xl mr-2 text-gray-600" />
          Messaggi ricevuti
          {unreadCount > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-2">
              {unreadCount}
            </span>
          )}
        </div>
      </Link>
      </div>
    </div>
  );
};

export default AdminDashboard;


