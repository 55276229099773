import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, get, update, remove } from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  deleteObject,
  getDownloadURL,
  getMetadata,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddBrandForm = () => {
  const db = getDatabase();
  const storage = getStorage();
  const [brands, setBrands] = useState([]);
  const [brandData, setBrandData] = useState({
    name: '',
    image: null,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false); // Variabile di stato per il caricamento
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [newImage, setNewImage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBrandData({ ...brandData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setBrandData({ ...brandData, image: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!brandData || !brandData.image) {
      return;
    }

    setLoading(true); // Imposta lo stato di caricamento a true

    const imageRef = storageRef(
      storage,
      `brands/${brandData.name}/${brandData.image.name}`
    );
    await uploadBytes(imageRef, brandData.image);

    const imageUrl = await getDownloadURL(imageRef);

    const brandId = uuidv4();
    const brandsRef = ref(db, `brands/${brandId}`);
    set(brandsRef, {
      name: brandData.name,
      imageUrl: imageUrl,
    })
      .then(() => {
        console.log('Marca aggiunta con successo!');
        toast.success('Marca aggiunta con successo!');
        setBrandData({
          name: '',
          image: null,
        });

        const dbRef = ref(db, 'brands');
        set(dbRef.child(brandId), {
          name: brandData.name,
          imageUrl: imageUrl,
        });
      })
      .catch((error) => {
        console.error("Errore durante l'aggiunta della marca:", error);
      })
      .finally(() => {
        setLoading(false); // Imposta lo stato di caricamento a false dopo il completamento
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const brandsRef = ref(db, 'brands');
        const snapshot = await get(brandsRef);
        if (snapshot.exists()) {
          const brandsList = [];
          snapshot.forEach((childSnapshot) => {
            const brand = childSnapshot.val();
            brand.id = childSnapshot.key;
            brandsList.push(brand);
          });
          setBrands(brandsList);
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Filter brands based on search term
  const filteredBrands = brands.filter((brand) =>
    brand.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();

    if (!selectedBrandId || !newImage) {
      return;
    }

    const brand = brands.find((brand) => brand.id === selectedBrandId);
    if (!brand) {
      console.error('Brand not found');
      return;
    }

    const oldImageUrl = brand.imageUrl;
    const oldImagePath = new URL(oldImageUrl).pathname.slice(1); // Rimuove lo slash iniziale

    try {
      // Verifica se l'immagine precedente esiste
      const oldImageRef = storageRef(storage, oldImagePath);
      await getMetadata(oldImageRef);

      // Se esiste, elimina l'immagine precedente dallo storage
      await deleteObject(oldImageRef);
    } catch (error) {
      // Se l'immagine precedente non esiste, ignora l'errore
      if (error.code !== 'storage/object-not-found') {
        console.error('Error deleting previous image:', error);
      }
    }

    // Carica la nuova immagine nello storage
    const imageRef = storageRef(
      storage,
      `brands/${brand.name}/${newImage.name}`
    );
    await uploadBytes(imageRef, newImage);

    // Ottieni l'URL della nuova immagine
    const imageUrl = await getDownloadURL(imageRef);

    // Aggiorna il database con la nuova immagine
    const brandRef = ref(db, `brands/${selectedBrandId}`);
    await update(brandRef, {
      imageUrl: imageUrl,
    });

    setSelectedBrandId(null);
    setNewImage(null);
    toast.success('Immagine aggiornata con successo!');
  };

  const handleDeleteBrand = async (brandId, imageName) => {
    try {
      const brandRef = ref(db, `brands/${brandId}`);
      await remove(brandRef);
      const imageUrl = `brands/${
        brands.find((brand) => brand.id === brandId).name
      }/${imageName}`;
      await deleteObject(storageRef(storage, imageUrl));
      setBrands(brands.filter((brand) => brand.id !== brandId));
      toast.success('Marca eliminata con successo!');
    } catch (error) {}
  };

  return (
    <div>
      <ToastContainer />

      <form
        onSubmit={handleSubmit}
        className="w-full max-w-lg mx-auto mt-10 p-8 bg-white shadow-lg rounded-lg"
      >
        <div className="mb-4  ">
          <label
            htmlFor="name"
            className="  block text-gray-700 font-bold mb-2"
          >
            Nome Marca:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Inserisci il nome della marca"
            value={brandData.name}
            onChange={handleInputChange}
            className="input-field"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="image" className="block text-gray-700 font-bold mb-2">
            Immagine Marca:
          </label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleImageChange}
            className="input-field"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={loading}
        >
          {loading ? 'Sto aggiungendo la marca...' : 'Aggiungi Marca'}
        </button>
      </form>

      <div className="p-4">
        <input
          type="text"
          placeholder="Cerca per nome"
          value={searchTerm}
          onChange={handleSearchTermChange}
          className="border border-gray-300 rounded px-3 py-1 mb-4"
        />
        <h2 className="text-2xl font-bold mb-4">Elenco Marche</h2>
        <ul className="space-y-4">
          {filteredBrands.map((brand) => (
            <li
              key={brand.id}
              className="flex justify-between items-center border-b pb-2"
            >
              <div className="flex flex-col items-center">
                <div className="flex items-center">
                  <img
                    src={brand.imageUrl}
                    alt={brand.name}
                    className="w-12 h-12 mr-4"
                  />
                  <strong className="text-lg">{brand.name}</strong>
                </div>
                <div className="flex space-x-2 mt-1">
                  <button
                    onClick={() =>
                      handleDeleteBrand(
                        brand.id,
                        brand.imageUrl.split('/').pop()
                      )
                    }
                    className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded focus:outline-none focus:ring focus:ring-red-400"
                  >
                    Elimina Marca
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AddBrandForm;
